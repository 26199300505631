import {
  Grid,
  IconButton,
  Stack,
  Button,
  CircularProgress,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  TextField,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import React, { useState, useEffect } from "react";
import axios from "axios";
import IshaHeader from "./IshaHeader";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import CloseIcon from "@mui/icons-material/Close";
import UploadIcon from "@mui/icons-material/Upload";
import { default as ReactImageUploading } from "react-images-uploading";
import { format } from "date-fns";
import { setIshaUserImages } from "../BookingDashboard/apis/BrandingModalApi";
import moment from "moment";
import { getUserIdIsha } from "../../pages/BookingDashboard/apis/BrandingModalApi";
import { ToastContainer, toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import imageCompression from "browser-image-compression";
import { maxCompressMBSize } from "../CalendarPage/constants/Calendar-constants";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const IshaEnquiryValidation = () => {
  const url = new URL(window.location.href);
  // const urlname = url.searchParams.get("name");
  const hash = url.searchParams.get("hash");
  const [urlIsAcc, seturlIsAcc] = useState("false");
  const [bookingId, setbookingId] = useState("");
  const [cancelled, setCancelled] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  useEffect(async () => {
    await axios
      .post(
        `${process.env.REACT_APP_SPRING_API_URL}` +
          "core/api/v1/reservation/get-guest-data",
        {
          hashval: hash.split(" ").join("+"),
        }
      )
      .then((resp) => {
        const currentEpoch = moment().unix();
        setemail(resp.data.email);
        sethotelId(resp.data.hotel_id);
        setphone(resp.data.mobile_no);
        setuserName(resp.data.name);
        if (resp.data.confirmed === "NO") {
          setExpiry(
            resp?.data?.is_acc.toString() === "false"
              ? Number(currentEpoch) < Number(resp?.data?.expiry_timestamp)
                ? false
                : true
              : false
          );
        } else {
          setExpiry(false);
        }

        setCancelled(resp?.data?.cancelled === "YES" ? true : false);
        seturlIsAcc(resp?.data?.is_acc.toString());
        setbookingId(resp?.data?.booking_id);

        // Redirection logic

        const redirectUrl = `https://cottage.isha.in//myaccount/customlogincallback/precheckin/${resp?.data?.booking_id}`;
        window.location.href = redirectUrl;
      })
      .catch((err) => {
        throw new Error(err);
      });
  }, [hash]);

  const [Expiry, setExpiry] = useState(false);
  // useEffect(() => {

  //   setExpiry(< Number(expiry_time) ? false : true);
  // }, [expiry_time]);

  const [images, setImages] = useState([]);
  const [govIdType, setGovIdType] = useState("Passport");
  const [emergencyContactNumber, setemergencyContactNumber] = useState("");
  const govIdIndia = [
    "Passport",
    "Aadhar",
    "Drivers License",
    "Voter ID",
    "Others",
  ];

  const [showFrontImagePopup, setshowFrontImagePopup] = useState(false);
  const [showPopUpImages, setshowPopUpImages] = useState("");
  const [faviconImages, setFaviconImages] = useState([]);
  const [passportImages, setPassportImages] = useState([]);
  const [names, setNames] = useState("");

  const [image, setImage] = useState(undefined);
  const [backImage, setBackImage] = useState(undefined);
  const [passportImage, setpassportImage] = useState(undefined);
  const [loader, setloader] = useState(false);
  const [ApprovedEnquiry, setApprovedEnquiry] = useState(false);
  const [uploadedDetails, setuploadedDetails] = useState("");
  const [phone, setphone] = useState(0);
  const [hotelId, sethotelId] = useState("");
  const [email, setemail] = useState("");
  const [userName, setuserName] = useState("");

  const handleshowFrontImagePopupClose = () => setshowFrontImagePopup(false);
  const handleFrontImagePopupShow = (data) => {
    setshowPopUpImages(data);
    setshowFrontImagePopup(true);
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result;

        resolve(baseURL);
      };
    });
  };

  const saveDetails = async () => {
    if (urlIsAcc === "false") {
      let trimmed_name = names.trim();
      if (checkName(trimmed_name) === false) {
        toast.error("Enter name in alphabet");
      } else if (validatePhoneNumber(emergencyContactNumber) === false) {
        toast.error("Please enter correct mobile number");
      } else {
        setloader(true);
        const options = {
          maxSizeMB: maxCompressMBSize,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        };
        let frontResults;
        let backResults;
        let passResults;
        if (images?.length > 0) {
          const frontImage = images[0].file;
          const compressedFile = await imageCompression(frontImage, options);
          await getBase64(compressedFile)
            .then((result) => {
              compressedFile["base64"] = result;
              frontResults = result;
              setImage(result);
            })
            .catch((err) => {
              console.log(err);
            });
        }
        if (faviconImages?.length > 0) {
          const backImagess = faviconImages[0].file;
          const compressedFile = await imageCompression(backImagess, options);
          await getBase64(compressedFile)
            .then((result) => {
              compressedFile["base64"] = result;

              backResults = result;
              setBackImage(result);
            })
            .catch((err) => {
              console.log(err);
            });
        }
        if (passportImages?.length > 0) {
          const passImagess = passportImages[0].file;
          const compressedFile = await imageCompression(passImagess, options);
          await getBase64(compressedFile)
            .then((result) => {
              compressedFile["base64"] = result;
              setpassportImage(result);
              passResults = result;
            })
            .catch((err) => {
              console.log(err);
            });
        }

        if (urlIsAcc === "false") {
          const userIdPayload = {
            emailId: email,
            frontData: frontResults?.split(",")[1],
            // image !== undefined
            //   ? image.split(",")[1]
            //   : images[0]
            //   ? images[0].data_url?.split(",")[1]
            //   : null,
            frontType:
              image !== undefined
                ? "jpeg"
                : images[0]
                ? images[0].file.type
                : null,
            backData: backResults?.split(",")[1],
            // backImage !== undefined
            //   ? backImage.split(",")[1]
            //   : faviconImages[0]
            //   ? faviconImages[0].data_url?.split(",")[1]
            //   : null,
            backType:
              backImage !== undefined
                ? "jpeg"
                : faviconImages[0]
                ? faviconImages[0].file.type
                : null,
            hotelId: hotelId,
            govtIdType: govIdType,
            bookingId: bookingId,
            primaryGuest: urlIsAcc === "false" ? "true" : "false",
            emergencyContact: emergencyContactNumber,
            emergencyContactName: trimmed_name,
            passportImageData: passResults?.split(",")[1],
            // passportImage !== undefined
            //   ? passportImage.split(",")[1]
            //   : passportImages[0]
            //   ? passportImages[0].data_url?.split(",")[1]
            //   : null,
            passportImageType:
              passportImage !== undefined
                ? "jpeg"
                : passportImages[0]
                ? passportImages[0].file.type
                : null,
          };

          if (emergencyContactNumber === "") {
            toast.error("Please enter emergency contact number");
            setloader(false);
          } else if (emergencyContactNumber === phone) {
            toast.error("Please enter alternate contact number");
            setloader(false);
          } else if (trimmed_name === "") {
            toast.error("Please enter emergency contact name");
            setloader(false);
          } else {
            if (
              images?.length > 0 &&
              faviconImages.length > 0 &&
              passportImages?.length > 0 &&
              names !== userName &&
              names !== ""
            ) {
              const data = await setIshaUserImages(userIdPayload);
              toast.success("Successfully uploaded id");
              if (data === "Operation Successful") {
                toast.success("Successfully uploaded id");
                setuploadedDetails("Operation");
                setloader(false);
              } else {
                toast.error("Something went wrong");
                setloader(false);
              }
            }
          }
        } else {
          const userIdPayload = {
            emailId: email,
            frontData: frontResults?.split(",")[1],
            // image !== undefined
            //   ? image.split(",")[1]
            //   : images[0]
            //   ? images[0].data_url?.split(",")[1]
            //   : null,
            frontType:
              image !== undefined
                ? "jpeg"
                : images[0]
                ? images[0].file.type
                : null,
            backData: backResults?.split(",")[1],
            // backImage !== undefined
            //   ? backImage.split(",")[1]
            //   : faviconImages[0]
            //   ? faviconImages[0].data_url?.split(",")[1]
            //   : null,
            backType:
              backImage !== undefined
                ? "jpeg"
                : faviconImages[0]
                ? faviconImages[0].file.type
                : null,
            hotelId: hotelId,
            govtIdType: govIdType,
            bookingId: bookingId,
            primaryGuest: urlIsAcc === "false" ? "true" : "false",
            emergencyContact: "",
            emergencyContactName: "",
            passportImageData: passResults?.split(",")[1],
            // passportImage !== undefined
            //   ? passportImage.split(",")[1]
            //   : passportImages[0]
            //   ? passportImages[0].data_url?.split(",")[1]
            //   : null,
            passportImageType:
              passportImage !== undefined
                ? "jpeg"
                : passportImages[0]
                ? passportImages[0].file.type
                : null,
          };

          if (
            images?.length > 0 &&
            faviconImages.length > 0 &&
            passportImages?.length > 0
          ) {
            setloader(true);
            const data = await setIshaUserImages(userIdPayload);

            if (data === "Operation Successful") {
              toast.success("Successfully uploaded id");
              setuploadedDetails("Operation");
              setloader(false);
            } else {
              toast.error("Something went wrong");
              setloader(false);
            }
          }
        }
      }
    } else {
      setloader(true);
      const options = {
        maxSizeMB: maxCompressMBSize,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      let frontResults;
      let backResults;
      let passResults;
      if (images?.length > 0) {
        const frontImage = images[0].file;
        const compressedFile = await imageCompression(frontImage, options);
        await getBase64(compressedFile)
          .then((result) => {
            compressedFile["base64"] = result;
            frontResults = result;
            setImage(result);
          })
          .catch((err) => {
            console.log(err);
          });
      }
      if (faviconImages?.length > 0) {
        const backImagess = faviconImages[0].file;
        const compressedFile = await imageCompression(backImagess, options);
        await getBase64(compressedFile)
          .then((result) => {
            compressedFile["base64"] = result;

            backResults = result;
            setBackImage(result);
          })
          .catch((err) => {
            console.log(err);
          });
      }
      if (passportImages?.length > 0) {
        const passImagess = passportImages[0].file;
        const compressedFile = await imageCompression(passImagess, options);
        await getBase64(compressedFile)
          .then((result) => {
            compressedFile["base64"] = result;
            setpassportImage(result);
            passResults = result;
          })
          .catch((err) => {
            console.log(err);
          });
      }

      if (urlIsAcc === "false") {
        const userIdPayload = {
          emailId: email.toLowerCase(),
          frontData: frontResults?.split(",")[1],
          // image !== undefined
          //   ? image.split(",")[1]
          //   : images[0]
          //   ? images[0].data_url?.split(",")[1]
          //   : null,
          frontType:
            image !== undefined
              ? "jpeg"
              : images[0]
              ? images[0].file.type
              : null,
          backData: backResults?.split(",")[1],
          // backImage !== undefined
          //   ? backImage.split(",")[1]
          //   : faviconImages[0]
          //   ? faviconImages[0].data_url?.split(",")[1]
          //   : null,
          backType:
            backImage !== undefined
              ? "jpeg"
              : faviconImages[0]
              ? faviconImages[0].file.type
              : null,
          hotelId: hotelId,
          govtIdType: govIdType,
          bookingId: bookingId,
          primaryGuest: urlIsAcc === "false" ? "true" : "false",
          emergencyContact: emergencyContactNumber,
          emergencyContactName: names,
          passportImageData: passResults?.split(",")[1],
          // passportImage !== undefined
          //   ? passportImage.split(",")[1]
          //   : passportImages[0]
          //   ? passportImages[0].data_url?.split(",")[1]
          //   : null,
          passportImageType:
            passportImage !== undefined
              ? "jpeg"
              : passportImages[0]
              ? passportImages[0].file.type
              : null,
        };

        if (emergencyContactNumber === "") {
          toast.error("Please enter emergency contact number");
          setloader(false);
        } else if (emergencyContactNumber === phone) {
          toast.error("Please enter alternate contact number");
          setloader(false);
        } else if (names === "") {
          toast.error("Please enter emergency contact name");
          setloader(false);
        } else {
          if (
            images?.length > 0 &&
            faviconImages.length > 0 &&
            passportImages?.length > 0 &&
            names !== userName &&
            names !== ""
          ) {
            const data = await setIshaUserImages(userIdPayload);
            toast.success("Successfully uploaded id");
            if (data === "Operation Successful") {
              toast.success("Successfully uploaded id");
              setuploadedDetails("Operation");
              setloader(false);
            } else {
              toast.error("Something went wrong");
              setloader(false);
            }
          }
        }
      } else {
        const userIdPayload = {
          emailId: email.toLowerCase(),
          frontData: frontResults?.split(",")[1],
          // image !== undefined
          //   ? image.split(",")[1]
          //   : images[0]
          //   ? images[0].data_url?.split(",")[1]
          //   : null,
          frontType:
            image !== undefined
              ? "jpeg"
              : images[0]
              ? images[0].file.type
              : null,
          backData: backResults?.split(",")[1],
          // backImage !== undefined
          //   ? backImage.split(",")[1]
          //   : faviconImages[0]
          //   ? faviconImages[0].data_url?.split(",")[1]
          //   : null,
          backType:
            backImage !== undefined
              ? "jpeg"
              : faviconImages[0]
              ? faviconImages[0].file.type
              : null,
          hotelId: hotelId,
          govtIdType: govIdType,
          bookingId: bookingId,
          primaryGuest: urlIsAcc === "false" ? "true" : "false",
          emergencyContact: "",
          emergencyContactName: "",
          passportImageData: passResults?.split(",")[1],
          // passportImage !== undefined
          //   ? passportImage.split(",")[1]
          //   : passportImages[0]
          //   ? passportImages[0].data_url?.split(",")[1]
          //   : null,
          passportImageType:
            passportImage !== undefined
              ? "jpeg"
              : passportImages[0]
              ? passportImages[0].file.type
              : null,
        };

        if (
          images?.length > 0 &&
          faviconImages.length > 0 &&
          passportImages?.length > 0
        ) {
          setloader(true);
          const data = await setIshaUserImages(userIdPayload);

          if (data === "Operation Successful") {
            toast.success("Successfully uploaded id");
            setuploadedDetails("Operation");
            setloader(false);
          } else {
            toast.error("Something went wrong");
            setloader(false);
          }
        }
      }
    }
  };

  function checkName(inputValue) {
    // Regular expression pattern to match only letters and space (no numbers or special characters)
    const regex = /^[a-zA-Z\s.-]+$/;

    return regex.test(inputValue);
  }

  const validatePhoneNumber = (number) => {
    // Define the regex pattern for a valid phone number
    // const phoneRegex = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[7896]\d{9}$/; // Assumes a 10-digit phone number format
    const phoneRegex = /^[0-9+]{6,16}$/;
    return phoneRegex.test(number);
  };

  return (
    <div className="ishaEnquiryValPage">
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        wid
      />
      <IshaHeader />

      <div className="ishaInfoWrapper">
        <div className="validationMsgWrapper">
          <strong>Namaskaram {userName}</strong>
          {Expiry && (
            <>
              <p className="validationMsg">
                We regret to inform you that your enquiry for stay at Isha Yoga
                Center was cancelled due to expiry of the pre-confirmation link.
                Please send an email to support.cottage@ishafoundation.org for
                any further information.
              </p>

              <p
                style={{
                  fontWeight: "200",
                  fontSize: "2rem",
                  fontSize: "17px",
                }}
              >
                In love light and laughter, Isha Volunteers
              </p>
            </>
          )}
          {uploadedDetails !== "" && (
            <>
              <p
                style={{
                  fontWeight: "300",
                  fontSize: "2rem",
                }}
              >
                {urlIsAcc === "false"
                  ? "Thanks for confirming the booking, you will receive a confirmation email with details"
                  : "Thanks for submitting the guest details"}
              </p>

              <p
                style={{
                  fontWeight: "200",
                  fontSize: "2rem",
                  fontSize: "17px",
                }}
              >
                In love light and laughter, Isha Volunteers
              </p>
            </>
          )}
          {!Expiry && uploadedDetails === "" && (
            <p
              style={{
                fontWeight: "300",
                fontSize: "2rem",
              }}
            >
              {`${
                urlIsAcc === false ? "To confirm the booking please" : "Please"
              }  upload id proof. (Allowed upload file types : JPG, PNG, JPEG)`}
            </p>
          )}
        </div>
        {!Expiry && uploadedDetails === "" && (
          <div className="addInfoWrapper">
            <div className="addInfo">
              <h4>Please select government id type</h4>
              <div className="infoField">
                <FormControl fullwidth sx={{ width: "100%" }}>
                  <InputLabel id="demo-simple-select-label">
                    Government id type
                  </InputLabel>
                  <Select
                    name="govt_id_type"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={govIdType}
                    defaultValue={govIdType}
                    label="Government id type"
                    onChange={(e) => setGovIdType(e.target.value)}
                    sx={{ width: "100%" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    {govIdIndia.map((item) => (
                      <MenuItem value={item}>{item}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="addInfo">
              {urlIsAcc === "false" && <h4>Emergency contact name</h4>}
              <div className="infoField">
                {names !== userName && urlIsAcc === "false" ? (
                  <TextField
                    label="Name"
                    sx={{ width: "100%" }}
                    placeholder="Enter contact name"
                    onChange={(e) => setNames(e.target.value)}
                    value={names}
                    name="Name"
                    id="Name"
                  />
                ) : (
                  <>
                    {urlIsAcc === "false" && (
                      <TextField
                        label="Name"
                        sx={{ width: "100%" }}
                        placeholder="Enter contact name"
                        onChange={(e) => setNames(e.target.value)}
                        value={names}
                        name="Name"
                        id="Name"
                        error
                        helperText={"Please enter alternate name"}
                      />
                    )}
                  </>
                )}
              </div>
            </div>

            <div className="addInfo">
              {urlIsAcc === "false" && <h4>Emergency contact number</h4>}
              <div className="infoField">
                {emergencyContactNumber !== phone && urlIsAcc === "false" ? (
                  <TextField
                    label="Contact"
                    sx={{ width: "100%" }}
                    placeholder="Enter contact number"
                    onChange={(e) => setemergencyContactNumber(e.target.value)}
                    value={emergencyContactNumber}
                    name="schedule_name"
                    id="schedule_name"
                  />
                ) : (
                  <>
                    {urlIsAcc === "false" && (
                      <TextField
                        label="Contact"
                        sx={{ width: "100%" }}
                        placeholder="Enter contact number"
                        onChange={(e) =>
                          setemergencyContactNumber(e.target.value)
                        }
                        value={emergencyContactNumber}
                        name="schedule_name"
                        id="schedule_name"
                        error
                        helperText={"Please enter alternate contact number"}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        )}

        {!Expiry && uploadedDetails === "" && (
          <Grid item xs={12} style={{ marginTop: "2rem" }}>
            <div className="uploadWrapper">
              <div className="uploadItem">
                <div className="d-flex">
                  <h5 className="mb-3 mr-3" style={{ marginRight: "13px" }}>
                    ID front
                  </h5>
                  <div
                    className="mb-3"
                    onClick={handleOpen}
                    style={{ color: "blue", cursor: "pointer" }}
                  >
                    Guidelines
                  </div>
                </div>
                <ReactImageUploading
                  value={images}
                  onChange={(image) => {
                    setImages(image);
                  }}
                  dataURLKey="data_url"
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                  }) => (
                    // write your building UI
                    <Stack
                      // p="2"
                      // bgColor="gray.50"
                      // borderRadius="lg"
                      className="upload__image-wrapper"
                      sx={{
                        borderRadius: "10px",
                        height: "17rem",
                        backgroundColor: "#d3d3d336",
                        padding: "1rem",
                        height: "20rem",
                      }}
                    >
                      {imageList.length === 0 && (
                        <IconButton
                          children={
                            <UploadIcon icon="bi:upload" htmlColor="black" />
                          }
                          variant="outline"
                          // height="100%"
                          sx={{
                            borderRadius: "20px",
                            backgroundColor: "#d3d3d3",
                            height: "100%",
                          }}
                          onClick={onImageUpload}
                          {...dragProps}
                        ></IconButton>
                      )}
                      <Stack spacing="2">
                        {imageList.map((image, index) => (
                          <Stack
                            sx={{
                              position: "relative",
                              padding: "0.5rem",
                            }}
                            key={index}
                          >
                            <img
                              src={
                                image["data_url"].includes("base64")
                                  ? image["data_url"]
                                  : image["data_url"] +
                                    "?lastmod=" +
                                    format(new Date(), "ddMMyyyyhhmmsstt")
                              }
                              style={{
                                height: "17rem",
                                width: "100%",
                                borderRadius: "1rem",
                                boxShadow: "md",
                                border: "1px solid",
                                borderColor: "gray.200",
                              }}
                              onClick={() => handleFrontImagePopupShow("front")}
                              alt=""
                            />

                            <IconButton
                              children={
                                <CloseIcon fontSize="xs" htmlColor="black" />
                              }
                              size="xs"
                              sx={{
                                position: "absolute",
                                borderRadius: "100%",
                                backgroundColor: "white",
                                top: "0",
                                // colorScheme="teal"
                                marginTop: "0px !important",
                                left: "0",
                                "&:hover": {
                                  color: "black",
                                  backgroundColor: "#ffffffcc",
                                },
                              }}
                              onClick={() => onImageRemove(index)}
                            />
                            <IconButton
                              children={
                                <ChangeCircleIcon
                                  fontSize="xs"
                                  htmlColor="black"
                                />
                              }
                              size="xs"
                              sx={{
                                position: "absolute",
                                borderRadius: "100%",
                                top: "0",
                                backgroundColor: "white",
                                // colorScheme="teal"
                                marginTop: "0px !important",
                                right: "0",
                                "&:hover": {
                                  color: "black",
                                  backgroundColor: "#ffffffcc",
                                },
                              }}
                              onClick={() => onImageUpdate(index)}
                            />
                          </Stack>
                        ))}
                      </Stack>
                    </Stack>
                  )}
                </ReactImageUploading>
                {images?.length <= 0 && (
                  <p className="text-danger">Please upload front id</p>
                )}
              </div>
              <div className="uploadItem">
                <div className="d-flex">
                  <h5 className="mb-3 mr-3" style={{ marginRight: "13px" }}>
                    ID Back
                  </h5>
                  <div
                    className="mb-3"
                    onClick={handleOpen}
                    style={{ color: "blue", cursor: "pointer" }}
                  >
                    Guidelines
                  </div>
                </div>
                <ReactImageUploading
                  value={faviconImages}
                  onChange={(image) => {
                    setFaviconImages(image);
                  }}
                  dataURLKey="data_url"
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                  }) => (
                    // write your building UI
                    <Stack
                      // p="2"
                      // bgColor="gray.50"
                      // borderRadius="lg"
                      className="upload__image-wrapper"
                      sx={{
                        borderRadius: "10px",
                        height: "17rem",
                        backgroundColor: "#d3d3d336",
                        padding: "1rem",
                        height: "20rem",
                      }}
                    >
                      {imageList.length === 0 && (
                        <IconButton
                          children={
                            <UploadIcon icon="bi:upload" htmlColor="black" />
                          }
                          variant="outline"
                          sx={{
                            borderRadius: "20px",
                            backgroundColor: "#d3d3d3",
                            height: "100%",
                          }}
                          onClick={onImageUpload}
                          {...dragProps}
                        ></IconButton>
                      )}
                      <Stack spacing="2">
                        {imageList.map((image, index) => (
                          <Stack
                            sx={{
                              position: "relative",
                              padding: "0.5rem",
                            }}
                            key={index}
                          >
                            <img
                              src={
                                image["data_url"].includes("base64")
                                  ? image["data_url"]
                                  : image["data_url"] +
                                    "?lastmod=" +
                                    format(new Date(), "ddMMyyyyhhmmsstt")
                              }
                              style={{
                                height: "17rem",
                                width: "100%",
                                borderRadius: "1rem",
                                boxShadow: "md",
                                border: "1px solid",
                                borderColor: "gray.200",
                              }}
                              onClick={() => handleFrontImagePopupShow("back")}
                              alt=""
                            />

                            <IconButton
                              children={
                                <CloseIcon fontSize="xs" htmlColor="black" />
                              }
                              size="xs"
                              sx={{
                                position: "absolute",
                                borderRadius: "100%",
                                backgroundColor: "white",
                                top: "0",
                                // colorScheme="teal"
                                marginTop: "0px !important",
                                left: "0",
                                "&:hover": {
                                  color: "black",
                                  backgroundColor: "#ffffffcc",
                                },
                              }}
                              onClick={() => onImageRemove(index)}
                            />
                            <IconButton
                              children={
                                <ChangeCircleIcon
                                  fontSize="xs"
                                  htmlColor="black"
                                />
                              }
                              size="xs"
                              sx={{
                                position: "absolute",
                                borderRadius: "100%",
                                top: "0",
                                backgroundColor: "white",
                                // colorScheme="teal"
                                marginTop: "0px !important",
                                right: "0",
                                "&:hover": {
                                  color: "black",
                                  backgroundColor: "#ffffffcc",
                                },
                              }}
                              onClick={() => onImageUpdate(index)}
                            />
                          </Stack>
                        ))}
                      </Stack>
                    </Stack>
                  )}
                </ReactImageUploading>
                {faviconImages?.length <= 0 && (
                  <p className="text-danger">Please upload back id</p>
                )}
              </div>

              <div className="uploadItem">
                <div className="d-flex">
                  <h5 className="mb-3 mr-3" style={{ marginRight: "13px" }}>
                    Your photo
                  </h5>
                  <div
                    className="mb-3"
                    onClick={handleOpen}
                    style={{ color: "blue", cursor: "pointer" }}
                  >
                    Guidelines
                  </div>
                </div>
                <ReactImageUploading
                  value={passportImages}
                  onChange={(image) => {
                    setPassportImages(image);
                  }}
                  dataURLKey="data_url"
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                  }) => (
                    // write your building UI
                    <Stack
                      // p="2"
                      // bgColor="gray.50"
                      // borderRadius="lg"
                      className="upload__image-wrapper"
                      sx={{
                        borderRadius: "10px",
                        height: "17rem",
                        backgroundColor: "#d3d3d336",
                        padding: "1rem",
                        height: "20rem",
                      }}
                    >
                      {imageList.length === 0 && (
                        <IconButton
                          children={
                            <UploadIcon icon="bi:upload" htmlColor="black" />
                          }
                          variant="outline"
                          sx={{
                            borderRadius: "20px",
                            backgroundColor: "#d3d3d3",
                            height: "100%",
                          }}
                          onClick={onImageUpload}
                          {...dragProps}
                        ></IconButton>
                      )}
                      <Stack spacing="2">
                        {imageList.map((image, index) => (
                          <Stack
                            sx={{
                              position: "relative",
                              padding: "0.5rem",
                            }}
                            key={index}
                          >
                            <img
                              src={
                                image["data_url"].includes("base64")
                                  ? image["data_url"]
                                  : image["data_url"] +
                                    "?lastmod=" +
                                    format(new Date(), "ddMMyyyyhhmmsstt")
                              }
                              style={{
                                height: "17rem",
                                width: "100%",
                                borderRadius: "1rem",
                                boxShadow: "md",
                                border: "1px solid",
                                borderColor: "gray.200",
                              }}
                              // onClick={() => handleFrontImagePopupShow("back")}
                              alt=""
                            />

                            <IconButton
                              children={
                                <CloseIcon fontSize="xs" htmlColor="black" />
                              }
                              size="xs"
                              sx={{
                                position: "absolute",
                                borderRadius: "100%",
                                backgroundColor: "white",
                                top: "0",
                                // colorScheme="teal"
                                marginTop: "0px !important",
                                left: "0",
                                "&:hover": {
                                  color: "black",
                                  backgroundColor: "#ffffffcc",
                                },
                              }}
                              onClick={() => onImageRemove(index)}
                            />
                            <IconButton
                              children={
                                <ChangeCircleIcon
                                  fontSize="xs"
                                  htmlColor="black"
                                />
                              }
                              size="xs"
                              sx={{
                                position: "absolute",
                                borderRadius: "100%",
                                top: "0",
                                backgroundColor: "white",
                                // colorScheme="teal"
                                marginTop: "0px !important",
                                right: "0",
                                "&:hover": {
                                  color: "black",
                                  backgroundColor: "#ffffffcc",
                                },
                              }}
                              onClick={() => onImageUpdate(index)}
                            />
                          </Stack>
                        ))}
                      </Stack>
                    </Stack>
                  )}
                </ReactImageUploading>
                {passportImages?.length <= 0 && (
                  <p className="text-danger">Please upload your photo</p>
                )}
              </div>
            </div>
          </Grid>
        )}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Guidelines for ID Front & ID Back:
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <ol>
                <li>
                  The numbers, address and the photo on the ID proof should be
                  clear and legible.
                </li>
                <li>
                  Ensure that you hold the camera straight over the ID proof if
                  you are using the camera.
                </li>
                <li>
                  Please do not upload photos of ID proofs that are slanted,
                  rotated or a mirror image of the original proof.
                </li>
                <li>
                  Distorted, filtered or cropped images will not be accepted.
                </li>
              </ol>
            </Typography>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Guidelines for Photo:
            </Typography>
            <img
              src={
                " https://prop-images.s3.us-east-2.amazonaws.com/bookingChannels/Photo_Guidelines.jpg"
              }
              style={{
                width: "100%",
                height: "40%",
                marginTop: "1rem",
                marginBottom: "1rem",
              }}
              alt="fireSpot"
            />
          </Box>
        </Modal>
        {!Expiry && uploadedDetails === "" && !cancelled && (
          <div className="saveBtnWrapper">
            <Button
              variant="custom-button"
              onClick={saveDetails}
              type="submit"
              disabled={loader}
              className="saveBtn"
              // style={{ float: "right", marginRight: "3%", marginTop: "1rem" }}
            >
              {loader ? (
                <CircularProgress size={15} color="inherit" sx={{ ml: 1.5 }} />
              ) : (
                " Save details"
              )}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default IshaEnquiryValidation;
