import {
  Button,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../../api";
import {
  assignRoom,
  getReservationInfoDjango,
  getUpgradePricePayload,
} from "../../CalendarPage/api/ReservationApi";
import { PriceModel } from "../../CalendarPage/models/PricesModel";
import { getAllRoomTypess } from "../../RevenueManagement/RatePlans/apis/RatePlanApis";
import { useFormik } from "formik";
import * as yup from "yup";
import { shallowEqual, useSelector } from "react-redux";
import { toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import { HorizontalRule } from "@mui/icons-material";
import ReassignRoomFolio from "./ReassignRoomFolio";

function AssignRoom(props) {
  const { hotel } = useSelector(
    ({ auth }) => ({
      hotel: auth.selectedHotel,
    }),
    shallowEqual
  );
  const hotelId = hotel.hotelId;
  const currency = hotel.hotelCurrency;
  const [loader, setloader] = useState(false);
  const [assignedRes, setassignedRes] = useState([]);
  const [showReassign, setShowReassign] = useState(false);
  const [selectedData, setselectedData] = useState(null);

  const getAllData = () => {
    handleSpringDataRequest(
      `core/api/v1/reservation/unassigned-res-list?bookingId=${props.bookingId}`
    )
      .then((res) => setassignedRes(res))
      .catch((err) => toast.error("Somthing went wrong"));
  };

  useEffect(() => {
    getAllData();
  }, []);

  const [balanceDue, setbalanceDue] = useState(null);

  useEffect(async () => {
    if (
      selectedData !== null &&
      selectedData !== undefined &&
      Object.entries(selectedData).length > 0 &&
      selectedData?.reservationId !== null &&
      selectedData?.reservationId !== undefined
    ) {
      const data = await getReservationInfoDjango(selectedData?.reservationId);

      setbalanceDue(data?.balance_due);
    }
  }, [selectedData]);

  return (
    <div>
      <div id="external-events">
        {assignedRes.length === 0 ? (
          <h2 style={{ marginLeft: "14rem", marginTop: "2rem" }}>
            All rooms are already assigned
          </h2>
        ) : (
          assignedRes.map((resrv) => (
            <Card
              key={resrv.reservationId}
              variant="outlined"
              sx={{ mb: 1 }}
              style={{
                marginTop: "1rem",
                marginLeft: "2rem",
                marginRight: "2rem",
              }}
              className="fc-event"
            >
              <CardHeader
                sx={{ pb: 1 }}
                title={resrv?.name}
                action={
                  <Grid className="d-flex align-items-center">
                    <Button
                      variant="custom-button"
                      onClick={() => {
                        setShowReassign(true);
                        setselectedData(resrv);
                      }}
                    >
                      Assign
                    </Button>
                  </Grid>
                }
              ></CardHeader>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item container>
                    <div className="d-flex flex-column">
                      <Typography>{`${resrv.bookingId}`}</Typography>
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          ))
        )}
        {showReassign && balanceDue != null && (
          <ReassignRoomFolio
            open={showReassign}
            handleAddEventSidebar={() => {
              setShowReassign(false);
              setselectedData({});
              props.updateInvoice();
              props.updateSummaryData();
            }}
            getData={getAllData}
            eventData={selectedData}
            refetch={() => {
              setShowReassign(false);
              setselectedData({});
              props.updateInvoice();
              props.updateSummaryData();
            }}
            balanceDue={balanceDue}
          />
        )}
      </div>
    </div>
  );
}

export default AssignRoom;
