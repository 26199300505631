import React, { useState, useEffect } from "react";
import {
  Box,
  CircularProgress,
  ClickAwayListener,
  Divider,
  Fab,
  FormControl,
  Grid,
  Grow,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Popper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import CommonSlider from "./CommonSlider";
import moment from "moment";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRange } from "react-date-range";
import {
  checkIshaAccess,
  checkIshaUSAccess,
  dateFormat,
  displaySlots,
} from "../constants/Calendar-constants";
import { blockMultipleRooms } from "../../InventoryCalendar/api/InventoryApi";
import { shallowEqual, useSelector } from "react-redux";
import { toast } from "material-react-toastify";
import { handleSpringDataRequest } from "../../../api";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    padding: theme.spacing(2),
  },
  custom_box: {
    width: "100%",
    padding: theme.spacing(2),
    whiteSpace: "no-wrap",
    borderRadius: 10,
  },
}));

const BlockRoom = (props) => {
  const { hotel } = useSelector(
    ({ auth }) => ({
      hotel: auth.selectedHotel,
    }),
    shallowEqual
  );
  const { onClose, reload, modalClose, reloadagain, viewType } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentDateSelection, setCurrentDateSelection] = useState([
    {
      startDate: new Date(),
      endDate: new Date(new Date().getTime() + 86400000),
    },
  ]);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [reason, setReason] = useState("");

  const [allRoomTypes, setallRoomTypes] = useState([]);
  const [allRoomIds, setallRoomIds] = useState([]);
  const [selectedRoomId, setselectedRoomId] = useState("");
  const [selectedRoomTypeId, setselectedRoomTypeId] = useState("");
  const [blockType, setblockType] = useState("MANUAL");

  const getAllRoomsTypes = () => {
    handleSpringDataRequest("core/api/v1/reservation/getAllRoomTypes")
      .then((res) => {
        setallRoomTypes(Object.entries(res));
        setselectedRoomTypeId(Object.entries(res)[0][0]);
        console.log("sas", Object.entries(res)[0][0]);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getAllRoomsTypes();
  }, []);

  const getAvailableRoomId = () => {
    if (
      selectedRoomTypeId !== null &&
      selectedRoomTypeId !== undefined &&
      selectedRoomTypeId !== ""
    ) {
      handleSpringDataRequest(
        `core/api/v1/room/get-available-rooms?roomTypeId=${selectedRoomTypeId}`
      )
        .then((res) => {
          setallRoomIds(res.split(","));
          setselectedRoomId(res.split(",")[0]);
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    getAvailableRoomId();
  }, [selectedRoomTypeId]);

  const unblock = async () => {
    try {
      setLoading(true);
      const payload = {
        startDate: moment(currentDateSelection[0].startDate).format(dateFormat),
        endDate: moment(currentDateSelection[0].endDate).format(dateFormat),
        physicalRoomIds: [selectedRoomId],
        reason: reason,
      };
      await blockMultipleRooms([payload]).then(() => {
        toast.success("Successfully blocked");
      });
      reload();
      if (sessionStorage.getItem("types") === "month") {
        reloadagain();
        reloadagain();
      }

      onClose();
    } catch (e) {
      toast.error(
        "Selected rooms could not be blocked since they have future reservations."
      );
      console.log(e);
      onClose();
    }
  };

  const getDateText = () => {
    let val = "";
    val =
      val +
      moment(currentDateSelection[0].startDate).format("DD MMM YYYY") +
      " - ";
    val = val + moment(currentDateSelection[0].endDate).format("DD MMM YYYY");
    return val;
  };

  const [blockRoomCodeData, setblockRoomCodeData] = useState([]);
  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = () => {
    handleSpringDataRequest(`core/api/v1/block-room-reason/get`)
      .then((res) => setblockRoomCodeData(res.map((item) => item.block_reason)))
      .catch((err) => console.error("Error in fetching the data"));
  };

  return (
    <div className={classes.root}>
      <Grid container className={classes.textField}>
        <Grid item xs={10} alignSelf="center">
          <Typography variant="h5">Block rooms</Typography>
        </Grid>
        <Grid item xs={2}>
          <IconButton
            onClick={() => {
              onClose();
            }}
            sx={{ float: "right" }}
          >
            <Close />
          </IconButton>
        </Grid>
      </Grid>

      <Grid container spacing={2} className={classes.textField}>
        <Grid item xs={8} sm={8} md={6} lg={6} xl={6}>
          {" "}
          <FormControl fullWidth sx={{ marginRight: "1rem" }}>
            <InputLabel id="demo-simple-select-label">Room Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedRoomTypeId}
              onChange={(e) => setselectedRoomTypeId(e.target.value)}
              label="Room Type"
            >
              {allRoomTypes?.length > 0 &&
                allRoomTypes?.map((name) => {
                  return <MenuItem value={name[0]}>{name[1]}</MenuItem>;
                })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={8} sm={8} md={6} lg={6} xl={6}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Room Id</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedRoomId}
              onChange={(e) => setselectedRoomId(e.target.value)}
              label="Room id"
            >
              {allRoomIds?.length > 0 &&
                allRoomIds?.map((name) => {
                  return <MenuItem value={name}>{name}</MenuItem>;
                })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.custom_box} bgcolor="primary.main">
            <Typography>
              {`You are currently blocking room numbers : ${selectedRoomId}`}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <div>
            <ClickAwayListener
              onClickAway={() => {
                setShowDatePicker(false);
                setAnchorEl(null);
              }}
            >
              <div>
                <TextField
                  fullWidth
                  focused={showDatePicker}
                  id="currentDateSelection"
                  name="currentDateSelection"
                  InputProps={{ readOnly: true }}
                  value={getDateText()}
                  label="Date range"
                  onClick={(eve) => {
                    setAnchorEl(eve.currentTarget);
                    setShowDatePicker(true);
                  }}
                ></TextField>
                {showDatePicker && (
                  <Popper
                    style={{ zIndex: 2 }}
                    open={showDatePicker}
                    anchorEl={anchorEl}
                    placement="bottom"
                    transition
                    disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        style={{
                          transformOrigin: (placement = "bottom-end"),
                        }}
                        {...TransitionProps}
                      >
                        <Paper
                          elevation={5}
                          className="inventory-daterange-custom"
                        >
                          <DateRange
                            color="#1BC5BD"
                            rangeColors={["#1BC5BD"]}
                            editableDateInputs={false}
                            moveRangeOnFirstSelection={false}
                            onChange={(data) => {
                              console.log(data);
                              setCurrentDateSelection([{ ...data.range1 }]);
                            }}
                            ranges={currentDateSelection}
                            minDate={new Date()}
                          ></DateRange>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                )}
              </div>
            </ClickAwayListener>
          </div>
        </Grid>
        <Grid item xs={12}>
          {checkIshaAccess(hotel?.emailId) && (
            <TextField
              value={reason}
              label="Reason"
              fullWidth
              multiline
              rows={3}
              onChange={(eve) => {
                setReason(eve.target.value);
              }}
            ></TextField>
          )}

          {checkIshaUSAccess(hotel?.emailId) && (
            <div>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Type</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  onChange={(e) => setblockType(e.target.value)}
                  className="mr-4"
                  label="Type"
                  value={blockType}
                >
                  <MenuItem value={"MANUAL"}>{"MANUAL"}</MenuItem>
                  <MenuItem value={"PREDEFINED"}>{"PREDEFINED"}</MenuItem>
                </Select>
              </FormControl>

              {blockType === "MANUAL" && (
                <TextField
                  value={reason}
                  label="Reason"
                  fullWidth
                  multiline
                  style={{ marginTop: "1rem" }}
                  rows={3}
                  onChange={(eve) => {
                    setReason(eve.target.value);
                  }}
                ></TextField>
              )}
              {blockType === "PREDEFINED" && (
                <FormControl fullWidth style={{ marginTop: "1rem" }}>
                  <InputLabel id="demo-simple-select-label">
                    Block reason
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    onChange={(e) => setReason(e.target.value)}
                    className="mr-4"
                    label="Block reason"
                    value={reason}
                  >
                    <MenuItem value={""}>{"-"}</MenuItem>
                    {blockRoomCodeData?.map((item) => (
                      <MenuItem value={item}>{item}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </div>
          )}
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid
          item
          xs={12}
          container
          justifyContent="flex-end"
          className={classes.textField}
        >
          <Fab
            disabled={loading}
            type="button"
            color="primary"
            onClick={unblock}
            variant="extended"
          >
            Block room
            {loading && (
              <CircularProgress size={15} color="inherit" sx={{ ml: 1.5 }} />
            )}
          </Fab>
        </Grid>
      </Grid>
    </div>
  );
};

export default BlockRoom;
