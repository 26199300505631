// ** React Imports
import { Add, Close, Delete, Remove, SwapHoriz } from "@mui/icons-material";
import { DatePicker, LoadingButton, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import {
  djangoHandleDataMutationRequest,
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../../api";
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Grow,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Skeleton,
  Step,
  StepButton,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/styles";
import { useFormik } from "formik";
import { isArray } from "lodash-es";
import { toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as yup from "yup";
import { getCtaNamesNew } from "../../InventoryCalendar/api/InventoryApi";
import {
  addSegmentAndSubSegment,
  getCheckinCheckoutSlots,
  getCombinedPrice,
  getRoomBookings,
  getRoomBookingsNoon,
  getTaxNamesForHotel,
  handleGetDoorLockDetails,
  perfomAdminBooking,
  perfomBooking,
} from "../api/ReservationApi";
import {
  checkIshaAccess,
  checkIshaUSAccess,
  customErrorMessage,
  dateFormat,
} from "../constants/Calendar-constants";
import { MultipleBookingModel } from "../models/MultipleBookingModel";
import DetailsReview from "./DetailsReview";
import {
  setIshaGuestDocument,
  setIshaOtherGuestDocuments,
  setOtherGuestImages,
} from "../../BookingDashboard/apis/BrandingModalApi";
import GroupBookingDetails from "./GroupBookingDetails";
import { cardNumberCVVFormat, getCardType } from "../../../Utils/cardType";
import {
  getcustomPGinfo,
  saveCardInfo,
} from "../../RevenueManagement/Promotions/apis/PromotionApis";
import { shallowEqual, useSelector } from "react-redux";
import { throttle } from "lodash";
import { Modal } from "react-bootstrap";

const StyledAccordion = styled((props) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));
const StyledAccordionSummary = styled((props) => (
  <AccordionSummary {...props} />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  margin: "none",
  padding: theme.spacing(0),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
  borderBottom: "none",
}));
const phoneRegExp =
  /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
const validationSchema = yup.object({
  // customer_name: yup
  //   .string("Enter your Name")
  //   .required("Customer Name is required"),
  // customer_email: yup.string(),
  // customer_phone: yup
  //   .string()
  //   .matches(phoneRegExp, "Phone number is not valid"),
  state: yup.string().nullable(),
  city: yup.string().nullable(),
  country: yup.string().nullable(),
  address1: yup.string(),
  address2: yup.string(),
  zipcode: yup.string(),
  taxId: yup.string(),
  selectedTaxes: yup.array().nullable(),
  totalPrice: yup.number(),
});

const MultipleBookingView = (props) => {
  // ** Props
  const hotelCurrency = props?.data?.accessControl?.hotel?.hotel_currency;
  const hotelCountry = props?.data?.accessControl?.hotel?.hotel_country;
  const paymentChargeAccess =
    JSON.parse(
      props?.data?.accessControl?.user_feature_map?.PaymentChargeAccess
    ).read === "YES";

  const { onClose, hotelId, reload } = props;
  const today = new Date(new Date().setMinutes(0));
  const nextDay = new Date(new Date().setDate(new Date().getDate() + 1));
  const [checkinDate, setCheckinDate] = useState(today);
  const [checkoutDate, setCheckoutDate] = useState(
    new Date(nextDay.setMinutes(0))
  );
  const [bookingData, setBookingData] = useState({});
  const [showRooms, setShowRooms] = useState(false);
  const [tab, setTab] = useState(1);
  const [combos, setCombos] = useState({});
  const [ratePlans, setRatePlans] = useState({});
  const [roomIds, setRoomIds] = useState({});
  const [selectedRatePlan, setSelectedRatePlan] = useState({});
  const [dataLoading, setDataLoading] = useState(true);
  const [roomPrice, setRoomPrice] = useState({});
  const [numRooms, setNumRooms] = useState({});
  const [roomSelectionCount, setRoomSelectionCount] = useState({});
  const [currentSelection, setCurrentSelection] = useState(new Set());
  const [loading, setLoading] = useState(false);
  const [selfCheckin, setselfCheckin] = useState(false);
  const [slots, setSlots] = useState({ checkin: [], checkout: [] });
  const [selectedSlots, setSelectedSlots] = useState({
    checkin: "",
    checkout: "",
  });
  const [taxNames, setTaxNames] = useState(null);
  const [priceWithTax, setPriceWithTax] = useState({});
  const [showError, setShowError] = useState(false);
  const [perDayList, setPerDayList] = useState({});
  const [ctas, setCtas] = useState([
    // { cta_name: "walk-in", cta_email: "STAYFLEXI_HD", cta_id: null },
  ]);

  const [primaryEmail, setprimaryEmail] = useState("");
  const [primaryPhone, setprimaryPhone] = useState("");
  const [primaryName, setprimaryName] = useState("");
  const [selectedCta, setSelectedCta] = useState({
    cta_name: "walk-in",
    cta_email: "STAYFLEXI_HD",
    cta_id: null,
  });

  const { hotel } = useSelector(
    ({ auth }) => ({
      hotel: auth.selectedHotel,
    }),
    shallowEqual
  );

  //payment flows
  const [subUser, setsubUser] = useState(hotel?.accessControl?.ADMINRIGHTS);
  const [paymentMode, setpaymentMode] = useState(
    hotelCountry === "United States" ? "SWIPE CARD" : "CASH"
  );
  const [paymentAmount, setpaymentAmount] = useState(0);
  const [remark, setremark] = useState("");

  const [showCollectpayment, setshowCollectpayment] = useState(false);

  useEffect(() => {
    getAllData();
  }, []);

  const [customPgInfo, setcustomPgInfo] = useState("");
  const getAllData = async () => {
    try {
      getcustomPGinfo().then((data) => {
        setcustomPgInfo(data?.active_pg_name);
      });
    } catch (e) {
      console.error(e);
    }
  };

  const toggleCollectpayment = (e) => {
    if (e.target.checked === true) {
      setshowCollectpayment(true);
    } else {
      setshowCollectpayment(false);
    }
  };

  const [addCardFirstName, setAddCardFirstName] = useState("");
  const [addCardLastName, setAddCardLastName] = useState("");
  const [addCardNumber, setAddCardNumber] = useState("");
  const [addCardType, setAddCardType] = useState();
  const [addCardCVV, setAddCardCVV] = useState("");
  const [addCardExpiry, setAddCardExpiry] = useState("");
  const [error, setError] = useState({
    firstName: false,
    lastName: false,
    number: false,
    cvv: false,
    expiry: false,
  });

  useEffect(() => {
    setAddCardNumber(addCardNumber?.replace(/\s/g, ""));
    if (addCardNumber?.length > 1) {
      const type = getCardType(addCardNumber?.slice(0, 16))?.type;
      setAddCardType(type);
    }
  }, [addCardNumber]);

  const checkExpiry = () => {
    const currentYear = new Date()?.getFullYear();
    const currentMonth = new Date()?.getMonth();
    const minExpiry =
      (currentMonth > 8 ? `${currentMonth + 1}` : `0${currentMonth + 1}`) +
      `${currentYear}`.slice(2, 4);
    const expiryValidity =
      addCardExpiry?.length === 4 &&
      (addCardExpiry?.slice(2, 4) > minExpiry?.slice(2, 4)
        ? true
        : addCardExpiry?.slice(2, 4) === minExpiry?.slice(2, 4)
        ? addCardExpiry?.slice(0, 2) >= minExpiry?.slice(0, 2)
        : false);
    return expiryValidity;
  };

  const checkLuhn = () => {
    let whitelist = [
      "4111111111111111",
      "4012888888881881",
      "4005562231212149",
      "5454545454545454",
      "5405222222222226",
      "6011000995500000",
      "371449635398431",
    ];
    if (whitelist.includes(addCardNumber)) {
      return true;
    }
    let s = 0;
    let doubleDigit = false;
    for (let i = addCardNumber?.length - 1; i >= 0; i--) {
      let digit = +addCardNumber[i];
      if (doubleDigit) {
        digit *= 2;
        if (digit > 9) digit -= 9;
      }
      s += digit;
      doubleDigit = !doubleDigit;
    }
    let flag = s % 10 === 0;
    if (flag) {
      // console.log("Luhn check passed");
    }
    return flag;
  };

  useEffect(() => {
    setError({
      firstName: false,
      lastName: false,
      number: false,
      cvv: false,
      expiry: false,
    });
  }, [
    addCardNumber,
    addCardExpiry,
    addCardFirstName,
    addCardLastName,
    addCardCVV,
  ]);

  useEffect(() => {
    console.log(error);
  }, [error]);

  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const toggleSelfCheckin = (e) => {
    if (e.target.checked === true) {
      setselfCheckin(true);
    } else {
      setselfCheckin(false);
    }
  };

  const [updatedOtherGuestsDetails, setUpdatedOtherGuestsDetails] = useState(
    []
  );
  const [blockReasonType, setblockReasonType] = useState("");

  // const setOtherGuestDataView = (otherGuests) => {
  //   setUpdatedOtherGuestsDetails(otherGuests);
  // };

  useEffect(() => {
    console.log("");
    // console.log("updated other guest documents: ", updatedOtherGuestDocuments);
    // console.log("updated other guest id proofs: ", updatedOtherGuestIdProofs);
  }, [
    updatedOtherGuestsDetails,
    updatedOtherGuestDocuments,
    updatedOtherGuestIdProofs,
  ]);

  const [updatedOtherGuestDocuments, setUpdatedOtherGuestDocuments] = useState(
    []
  );
  const setOtherGuestDocumentsView = (otherGuestDocs) => {
    setUpdatedOtherGuestDocuments(otherGuestDocs);
  };

  const [updatedOtherGuestIdProofs, setUpdatedOtherGuestIdProofs] = useState(
    []
  );
  const setOtherGuestIdProofsView = (otherGuestIds) => {
    setUpdatedOtherGuestIdProofs(otherGuestIds);
  };

  const [addedEntryCheck, setAddedEntryCheck] = useState([]);

  const updateAddedEntry = (ind, val) => {
    const updatedArr = addedEntryCheck.map((value, index) => {
      if (index === ind) {
        return val;
      } else {
        return value;
      }
    });
    setAddedEntryCheck(updatedArr);
  };

  useEffect(() => {
    console.log("");
  }, [addedEntryCheck]);
  useEffect(() => {
    let tempArr = [];
    if (Object.entries(bookingData).length > 0) {
      Object.keys(bookingData).map((roomTypeId, index) => {
        tempArr.push(false);
      });
      setAddedEntryCheck(tempArr);
    }
  }, [bookingData]);

  const gettingDifferenceOfCheckinAndInquiry = (
    checkInDate,
    EnquiryExpiryTime,
    expirationTime
  ) => {
    const checkInTime = checkInDate?.getTime();
    const currentTime = new Date().getTime();
    let inquiryTime = 0;
    if (expirationTime === "DAYS") {
      inquiryTime = EnquiryExpiryTime * 24 * 60 * 60 * 1000;
    } else if (expirationTime === "HOURS") {
      inquiryTime = EnquiryExpiryTime * 60 * 60 * 1000;
    } else {
      inquiryTime = EnquiryExpiryTime * 60 * 1000;
    }
    const diff = (checkInTime - inquiryTime - currentTime) / 60000;
    return diff;
  };

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {
      customer_name: "",
      customer_email: "",
      customer_phone: "",
      customer_gender: "Male",
      customer_dob: "",
      state: null,
      country: hotelCurrency === "USD" ? "United States" : null,
      city: "",
      nationality: "",
      occupation: "",
      address1: "",
      address2: "",
      zipcode: "",
      taxId: "",
      selectedTaxes: [],
      totalPrice: 0,
      govtIdType: "",
      govtIdValue: "",
      is_enquiry: false,
      expiry_time: "",
      expiry_type: "HOURS",
      duration_type: "BEFORE",
      special_requests: "",
      addons: [],
      appliedPromocode: "",
      promoAmount: 0,
      segment: segment,
      approvedByOverseas: "New",
      purposeOfVisit: "-",
      programsAttended: "-",
      emergencyContact: "",
      emergencyContactName: "",
      companyName: "",
      ETA: "",
      ETD: "",
      ocoCheckIn: "No",
      otherGuestDetails: [],
      requestorName: "",
      requestorContact: "",
      requestorDept: "",
      requestorApprover: "",
      requestorRemarks: "",
      bookingReferenceId: "",
      companyGstNumber: "",
      prpList: null, //will be added in case needed for future
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (parseFloat(values.totalPrice) <= 0) {
        return toast.error("Booking amount cannot be 0 or negative");
      }
      if (
        values?.is_enquiry === true &&
        ((values.expiry_type === "MINUTES" &&
          Number(values.expiry_time) <= 15) ||
          (values.expiry_type === "HOURS" && Number(values.expiry_time) <= 0) ||
          (values.expiry_type === "DAYS" && Number(values.expiry_time) <= 0) ||
          (values.duration_type === "BEFORE" &&
            gettingDifferenceOfCheckinAndInquiry(
              checkinDate,
              values?.expiry_time,
              values?.expiry_type
            ) < 15))
      ) {
        toast.error("Please enter enquiry time greater than 15 min");
      } else {
        let primaryGuestData = refUpdatedGuestData[0].primaryGuestData;

        if (primaryGuestData?.customerName?.value.trim().length <= 0) {
          return toast.error("Please enter characters in customer name");
        }
        const ctaResp = ctas.filter(
          (item) => item.cta_email === primaryGuestData?.customerEmail?.value
        );
        if (ctaResp.length > 0) {
          return toast.error(
            "Please enter different email. This email is already linked with direct billing account."
          );
        }
        if (
          subUser.toLowerCase() === "subuser" &&
          JSON.parse(
            props?.data?.accessControl?.user_feature_map?.MandatoryEmail
          ).read === "YES" &&
          primaryGuestData?.customerEmail?.value === ""
        ) {
          return toast.error("Please enter email");
        }
        if (
          subUser.toLowerCase() === "subuser" &&
          JSON.parse(
            props?.data?.accessControl?.user_feature_map?.MandatoryContactNumber
          ).read === "YES" &&
          primaryGuestData?.customerPhone?.value === ""
        ) {
          return toast.error("Please enter contact number");
        }
        if (
          subUser.toLowerCase() === "subuser" &&
          JSON.parse(
            props?.data?.accessControl?.user_feature_map?.MandatoryAddress
          ).read === "YES" &&
          primaryGuestData?.custAddress1?.value === ""
        ) {
          return toast.error("Please enter address");
        }
        if (
          subUser.toLowerCase() === "subuser" &&
          JSON.parse(
            props?.data?.accessControl?.user_feature_map?.MandatoryZipcode
          ).read === "YES" &&
          primaryGuestData?.custZipcode?.value === ""
        ) {
          return toast.error("Please enter zipcode");
        }
        values.customer_email = primaryGuestData?.customerEmail?.value;
        values.customer_name = primaryGuestData?.customerName?.value;
        values.customer_phone = primaryGuestData?.customerPhone?.value;
        values.customer_gender = primaryGuestData?.customerGender?.value;
        //need to change format
        if (
          primaryGuestData?.customerDOB?.value !== "" &&
          primaryGuestData?.customerDOB?.value !== "NA"
        ) {
          values.customer_dob = moment(
            primaryGuestData?.customerDOB?.value,
            "Do MMM YYYY"
          ).format("YYYY-MM-DD");
        } else {
          values.customer_dob = "";
        }
        values.state = primaryGuestData?.custState?.value;
        values.country = primaryGuestData?.custCountry?.value;
        values.city = primaryGuestData?.custCity?.value;
        values.nationality = primaryGuestData?.custNationality?.value;
        values.occupation = primaryGuestData?.custOccupation?.value;
        values.address1 = primaryGuestData?.custAddress1?.value;
        values.address2 = primaryGuestData?.custAddress2?.value;
        values.zipcode = primaryGuestData?.custZipcode?.value;
        values.govtIdType = primaryGuestData?.custGovtIDType?.value;
        values.govtIdValue = primaryGuestData?.custGovtIDValue?.value;
        values.approvedByOverseas =
          primaryGuestData?.custApprovedByOverseas?.value;
        values.purposeOfVisit = primaryGuestData?.custPurposeOfVisit?.value;
        values.programsAttended =
          primaryGuestData?.custProgramsAttended?.value.toString();
        values.emergencyContact = primaryGuestData?.custEmergencyContact?.value;
        values.emergencyContactName =
          primaryGuestData?.custEmergencyContactName?.value;
        values.companyName = primaryGuestData?.custCompany?.value;
        //Need to see the value being sent
        values.ETA = primaryGuestData?.custETA?.value;
        values.ETD = primaryGuestData?.custETD?.value;

        values.ocoCheckIn = primaryGuestData?.custOCOCheckin?.value;

        if (values.city.length > 50 && checkIshaAccess(props?.data?.emailId)) {
          return toast.error(
            "City length cannot be greater than 50 characters"
          );
        }

        if (values.city.length < 2 && checkIshaAccess(props?.data?.emailId)) {
          return toast.error("City length cannot be less than 2 characters");
        }

        if (
          primaryGuestData?.customerName?.value !== null &&
          primaryGuestData?.customerName?.value !== undefined &&
          primaryGuestData?.customerName?.value !== ""
        ) {
          throttledClick(values);
          // await createBooking();
        } else {
          toast.error("Please enter customer name");
        }
      }
    },
  });

  const getTaxNames = () => {
    getTaxNamesForHotel().then((data) => {
      setTaxNames(data);
      formik.setFieldValue("selectedTaxes", data);
    });
  };
  const [showexpires, setshowxepires] = useState(false);

  useEffect(() => {
    const data = localStorage.getItem("IsEnquiry");

    if (data !== "" && data !== null && data !== undefined) {
      setshowxepires(data);
    }
  }, [localStorage.getItem("IsEnquiry")]);

  const [currentEpoch, setcurrentEpoch] = useState(0);

  useEffect(() => {
    handleSpringDataRequest("web-api/get-today-hotel-epoch")
      .then((res) => setcurrentEpoch(res))
      .catch((err) => console.log(err));
  }, []);

  const createBooking = async (values) => {
    if (showCollectpayment && paymentMode === "SWIPE CARD") {
      if (addCardFirstName.trim().length === 0) {
        return toast.error("Please enter card first name");
      } else if (addCardLastName.trim().length === 0) {
        return toast.error("Please enter card last name");
      } else if (addCardNumber.trim().length === 0) {
        return toast.error("Please enter card number");
      } else if (addCardExpiry.trim().length === 0) {
        return toast.error("Please enter card expiry");
      }
    }
    setLoading(true);
    try {
      //Assign roomIds the same way in IBE to each otherguestdetail and iterate
      let payloadGuestDetails = [];
      updatedOtherGuestsDetails.map((roomWiseGuests, roomWiseIndex) => {
        if (roomWiseGuests?.length > 0) {
          roomWiseGuests.map((guest, guestIndex) => {
            let tempGuestDetail = guest;
            tempGuestDetail["roomIdAssigned"] = roomWiseIndex.toString();
            payloadGuestDetails.push(tempGuestDetail);
          });
        }
      });
      let dob = values.customer_dob;
      // if (values.customer_dob != "" && values.customer_dob != "NA") {
      //   let Datedob = new Date(values.customer_dob);
      //   dob = moment(Datedob).format("YYYY-MM-DD");
      // }
      let compRequest = "false";
      if (
        values.requestorName !== "" &&
        values.requestorContact !== "" &&
        values.requestorDept !== "" &&
        values.requestorApprover !== ""
      ) {
        compRequest = "true";
      }
      let subSource;
      // = subSegment === "WALK_IN" ? "STAYFLEXI_HD" : subSegment;
      if (subSegment === "WALK-IN") {
        subSource = "STAYFLEXI_HD";
      } else {
        subSource = subSegment;
      }
      let updatedRoomData = [];
      let ownerEmail =
        values.customer_email === ""
          ? values.customer_name.replace(/\s+/g, ".").toLowerCase() +
            hotelId.toString() +
            Math.round(Date.now() / 1000) +
            "@example.com"
          : values.customer_email;
      let ownerPhone =
        values.customer_phone === "" ? "0000000000" : values.customer_phone;

      for (let i = 0; i < refUpdatedGuestData?.length; i++) {
        let tempData = refUpdatedGuestData[i];
        let primaryEmail =
          tempData?.primaryGuestData?.customerEmail?.value === ""
            ? tempData?.primaryGuestData?.customerName?.value
                .replace(/\s+/g, ".")
                .toLowerCase() +
              hotelId.toString() +
              Math.round(Date.now() / 1000) +
              "@example.com"
            : tempData?.primaryGuestData?.customerEmail?.value;
        let primaryPhone =
          tempData?.primaryGuestData?.customerPhone?.value === ""
            ? "0000000000"
            : tempData?.primaryGuestData?.customerPhone?.value;
        let newPayload = {
          roomTypeName: tempData?.roomTypeName,
          roomTypeId: tempData?.roomTypeId,
          ratePlanName: tempData?.ratePlanName,
          ratePlanId: tempData?.ratePlanId,
          adults: tempData?.adults,
          children: tempData?.children,
          primaryGuestData: {
            customerName: tempData?.primaryGuestData?.customerName?.value,
            customerEmail: i === 0 ? ownerEmail : primaryEmail,
            customerPhone: i === 0 ? ownerPhone : primaryPhone,
            customerGender: tempData?.primaryGuestData?.customerGender?.value,
            customerDOB:
              tempData?.primaryGuestData?.customerDOB?.value != ""
                ? moment(
                    tempData?.primaryGuestData?.customerDOB?.value,
                    "Do MMM YYYY"
                  ).format("YYYY-MM-DD")
                : "",
            custGovtIDType: tempData?.primaryGuestData?.custGovtIDType?.value,
            custGovtIDValue: tempData?.primaryGuestData?.custGovtIDValue?.value,
            custAddress1: tempData?.primaryGuestData?.custAddress1?.value,
            custAddress2: tempData?.primaryGuestData?.custAddress2?.value,
            custZipcode: tempData?.primaryGuestData?.custZipcode?.value,
            custCountry: tempData?.primaryGuestData?.custCountry?.value,
            custState: tempData?.primaryGuestData?.custState?.value,
            custCity: tempData?.primaryGuestData?.custCity?.value,
            custNationality: tempData?.primaryGuestData?.custNationality?.value,
            custOccupation: tempData?.primaryGuestData?.custOccupation?.value,
            custCompany: tempData?.primaryGuestData?.custCompany?.value,
            custApprovedByOverseas:
              tempData?.primaryGuestData?.custApprovedByOverseas?.value,
            custPurposeOfVisit:
              tempData?.primaryGuestData?.custPurposeOfVisit?.value,
            custProgramsAttended:
              tempData?.primaryGuestData?.custProgramsAttended?.value.toString(),
            custEmergencyContactName:
              tempData?.primaryGuestData?.custEmergencyContactName?.value,
            custEmergencyContact:
              tempData?.primaryGuestData?.custEmergencyContact?.value,
            custETA: tempData?.primaryGuestData?.custETA?.value,
            custETD: tempData?.primaryGuestData?.custETD?.value,
            custOCOCheckin: tempData?.primaryGuestData?.custOCOCheckin?.value,
          },
          otherGuestData: tempData?.otherGuestData,
          childGuestData: tempData?.childGuestData,
        };
        updatedRoomData.push(newPayload);
      }
      const payload = {
        hotelId: hotelId,
        start: checkinDate,
        end: checkoutDate,
        title: values.customer_name,
        phoneNumber: ownerPhone.trim(),
        email: ownerEmail,
        gender: values.customer_gender,
        allData: formDisplayData(),
        zipcode: values.zipcode,
        address1: values.address1,
        address2: values.address2,
        state: values.state,
        country: values.country,
        nationality: values.nationality,
        occupation: values.occupation,
        city: values.city,
        taxId: values.taxId,
        taxNames: values.taxId === "" ? [] : values.selectedTaxes,
        totalPrice: values.totalPrice,
        govt_id_type: values.govt_id_type,
        govt_id_value: values.govt_id_value,
        bookingSource: subSource,
        is_enquiry: values.is_enquiry,
        expiry_time: values.expiry_time,
        expiry_type: values.expiry_type,
        duration_type: values.duration_type,
        special_requests: values.special_requests,
        addons: values.addons,
        appliedPromocode: values.appliedPromocode,
        promoAmount: values.promoAmount,
        segment: segment,
        purposeOfVisit: values.purposeOfVisit,
        programsAttended: values.programsAttended,
        emergencyContact: values.emergencyContact,
        emergencyContactName: values.emergencyContactName,
        companyName: values.companyName,
        dateOfBirth: dob,
        customer_eta: values.ETA,
        customer_etd: values.ETD,
        approvedByOverseas: values.approvedByOverseas,
        ocoCheckIn: values.ocoCheckIn,
        otherGuestDetails: payloadGuestDetails,
        requestorName: values.requestorName,
        requestorContact: values.requestorContact,
        requestorDept: values.requestorDept,
        requestorApprover: values.requestorApprover,
        requestorRemarks: values.requestorRemarks, //will be added in case needed for future
        isComplimentary: compRequest,
        roomData: updatedRoomData,
        bookingReferenceId: values.bookingReferenceId,
        companyGstNumber: values.companyGstNumber,
        prpList: values.prpList,
        ishaProgramReason: blockReasonType,
      };
      // if (
      //   values.appliedPromocode !== "" &&
      //   values.appliedPromocode !== undefined &&
      //   values.appliedPromocode !== null
      // ) {
      //   values.addons.push({
      //     id: values.addons?.length + 1,
      //     name: values.appliedPromocode.toUpperCase(),
      //     quantity: 1,
      //     priceWithoutTax: values.promoAmount * -1,
      //     taxPercentage: 0,
      //     priceWithTax: values.promoAmount * -1,
      //     description: values.appliedPromocode.toUpperCase(),
      //   });
      // }

      // if (
      //   values?.is_enquiry === true &&
      //   ((values.expiry_type === "MINUTES" &&
      //     Number(values.expiry_time) <= 15) ||
      //     (values.expiry_type === "HOURS" && Number(values.expiry_time) <= 0) ||
      //     (values.expiry_type === "DAYS" && Number(values.expiry_time) <= 0))
      //   // ||
      //   // (ExpirationSource === "BEFORE" &&
      //   //   gettingDifferenceOfCheckinAndInquiry(temporary?.start) < 15)
      // ) {
      //   toast.error("Please enter enquiry time greater than 15 min");
      // }

      // if (
      //   values.is_enquiry === true &&
      //   values.expiry_type === "MINUTES" &&
      //   Number(values.expiry_time) <= 15
      // ) {
      //   toast.error("Please enter enquiry time greater than 15 minutes");
      // }
      // else {
      localStorage.setItem("IsEnquiry", false);
      const request_payload = new MultipleBookingModel(payload);
      let resp = {};
      if (showRooms) {
        if (
          props?.data?.accessControl?.accessmap?.PastDateCreateBooking ===
            false &&
          moment(checkinDate).unix() < currentEpoch
        ) {
          toast.error("Past date create booking is not allowed");
        } else {
          resp = await perfomAdminBooking(
            request_payload.multipleBookingParams()
          );
          if (resp?.bookingId) {
            toast.success(
              "Booking successfully created with booking ID: " + resp?.bookingId
            );

            const paymentPayload = {
              card_first_name: addCardFirstName,
              card_last_name: addCardLastName,
              card_number: addCardNumber?.replace(/\s/g, "")?.slice(0, 16),
              card_exp_date: addCardExpiry,
              card_cvv: addCardCVV,
              email:
                values.customer_email === ""
                  ? values.customer_name.replace(/\s+/g, ".").toLowerCase() +
                    hotelId.toString() +
                    Math.round(Date.now() / 1000) +
                    "@example.com"
                  : values.customer_email,
              hotelId: hotelId,
              booking_id: resp?.bookingId,
            };
            if (
              addCardFirstName?.length > 0 &&
              !/^[0-9]*$/.test(addCardFirstName) &&
              addCardLastName?.length > 0 &&
              !/^[0-9]*$/.test(addCardLastName) &&
              checkExpiry() &&
              checkLuhn() &&
              addCardNumber.length > 12 &&
              addCardCVV?.length <= 4
            ) {
              if (paymentChargeAccess) {
                if (
                  customPgInfo === "FORTIS" ||
                  customPgInfo === "TSYS" ||
                  customPgInfo === "SHIFT4"
                ) {
                  await saveCardInfo(paymentPayload);
                }
              }
            }

            if (showCollectpayment) {
              if (paymentMode === "CASH") {
                handleCashPayment(resp.bookingId);
              } else if (paymentMode === "CARD") {
                handleOfflineCardPayment(resp.bookingId);
              } else if (paymentMode === "UPI") {
                handleUpiPayment(resp.bookingId);
              } else if (paymentMode === "OTHER") {
                handleOtherModePayment(resp.bookingId);
              } else if (paymentMode === "BANK TRANSFER") {
                handleBankTransferPayment(resp.bookingId);
              }
            }
          }

          const resIds = resp?.reservationId.split(",");

          let res;

          for (let i = 0; i < resIds?.length; i++) {
            if (selfCheckin) {
              try {
                res = await handleSpringDataMutationRequest(
                  "POST",
                  `core/api/v1/reservation/admin-confirmed-resids`,
                  { resIds: [resIds[i]], bookingId: resp.bookingId }
                );

                // console.log("multiple booking view : ", resIds[i]);
                await handleGetDoorLockDetails({
                  bookingId: resp.bookingId,
                  reservationId: resIds[i],
                  actionRequired: "make",
                });
              } catch (err) {
                console.log(err);
              }
              // handleSpringDataMutationRequest(
              //   "POST",
              //   `core/api/v1/reservation/admin-confirmed-resids`,
              //   { resIds: [resIds[i]], bookingId: resp.bookingId }
              // )
              //   .then((res) => console.log())
              //   .catch((err) => console.error(err));
            }
          }
        }
      } else {
        resp = await perfomBooking(request_payload.paramsForBooking());
        if (resp?.bookingId) {
          toast.success(
            "Booking successfully created with booking ID: " + resp?.bookingId
          );

          const paymentPayload = {
            card_first_name: addCardFirstName,
            card_last_name: addCardLastName,
            card_number: addCardNumber?.replace(/\s/g, "")?.slice(0, 16),
            card_exp_date: addCardExpiry,
            card_cvv: addCardCVV,
            email:
              values.customer_email === ""
                ? values.customer_name.replace(/\s+/g, ".").toLowerCase() +
                  hotelId.toString() +
                  Math.round(Date.now() / 1000) +
                  "@example.com"
                : values.customer_email,
            hotelId: hotelId,
            booking_id: resp?.bookingId,
          };
          if (
            addCardFirstName?.length > 0 &&
            !/^[0-9]*$/.test(addCardFirstName) &&
            addCardLastName?.length > 0 &&
            !/^[0-9]*$/.test(addCardLastName) &&
            checkExpiry() &&
            checkLuhn() &&
            addCardNumber.length > 12 &&
            addCardCVV?.length <= 4
          ) {
            if (paymentChargeAccess) {
              if (
                customPgInfo === "FORTIS" ||
                customPgInfo === "TSYS" ||
                customPgInfo === "SHIFT4"
              ) {
                await saveCardInfo(paymentPayload);
              }
            }
          }

          if (showCollectpayment) {
            if (paymentMode === "CASH") {
              handleCashPayment(resp.bookingId);
            } else if (paymentMode === "CARD") {
              handleOfflineCardPayment(resp.bookingId);
            } else if (paymentMode === "UPI") {
              handleUpiPayment(resp.bookingId);
            } else if (paymentMode === "OTHER") {
              handleOtherModePayment(resp.bookingId);
            } else if (paymentMode === "BANK TRANSFER") {
              handleBankTransferPayment(resp.bookingId);
            }
          }
        }
        const resIds = resp?.reservationId.split(",");
        let res;
        for (let i = 0; i < resIds?.length; i++) {
          if (selfCheckin) {
            try {
              res = await handleSpringDataMutationRequest(
                "POST",
                `core/api/v1/reservation/admin-confirmed-resids`,
                { resIds: [resIds[i]], bookingId: resp.bookingId }
              );

              // console.log("multiple booking view : ", resIds[i]);

              await handleGetDoorLockDetails({
                bookingId: resp.bookingId,
                reservationId: resIds[i],
                actionRequired: "make",
              });
            } catch (err) {
              console.log(err);
            }
          }
        }

        // for (let i = 0; i < resIds?.length; i++) {
        //   if (selfCheckin) {
        //     handleSpringDataMutationRequest(
        //       "POST",
        //       `core/api/v1/reservation/admin-confirmed-resids`,
        //       { resIds: [resIds[i]], bookingId: resp.bookingId }
        //     )
        //       .then((res) => console.log())
        //       .catch((err) => console.error(err));
        //   }
        // }
      }
      if (primaryGuestIdProofs?.length > 0) {
        let requestPayload = {
          hotelId: hotelId,
          otherGuestIdProofs: primaryGuestIdProofs,
        };
        await setOtherGuestImages(requestPayload);
      }
      if (otherGuestIdProofs?.length > 0) {
        let requestPayload = {
          hotelId: hotelId,
          otherGuestIdProofs: otherGuestIdProofs,
        };
        await setOtherGuestImages(requestPayload);
      }
      if (
        JSON.parse(
          props?.data?.accessControl?.hotel_feature_map?.IshaExtraGuestDetails
        ).read === "YES" &&
        (checkIshaAccess(props?.data?.emailId) ||
          checkIshaUSAccess(props?.data?.emailId))
      ) {
        await setIshaOtherGuestDocuments(primaryGuestDocuments);
        if (otherGuestDocuments?.length > 0) {
          let document_payload_arr = [];
          for (let i = 0; i < otherGuestDocuments?.length; i++) {
            let document_payload = {
              emailId: otherGuestDocuments[i]?.emailId,
              hotelId: hotelId,
            };
            if (otherGuestDocuments[i]?.doc1?.data) {
              document_payload["attachmentOne"] = {
                base64Data: otherGuestDocuments[i]?.doc1?.data,
                dataType: otherGuestDocuments[i]?.doc1?.type,
              };
            }
            if (otherGuestDocuments[i]?.doc2?.data) {
              document_payload["attachmentTwo"] = {
                base64Data: otherGuestDocuments[i]?.doc2?.data,
                dataType: otherGuestDocuments[i]?.doc2?.data,
              };
            }
            if (
              document_payload?.attachmentOne ||
              document_payload?.attachmentTwo
            ) {
              document_payload_arr.push(document_payload);
            }
          }
          if (document_payload_arr?.length > 0) {
            await setIshaOtherGuestDocuments(document_payload_arr);
          }
        }
      }
      // }

      // if (
      //   resp.bookingId !== null &&
      //   resp.bookingId !== undefined &&
      //   resp.bookingId !== ""
      // ) {
      //   const response = await addSegmentAndSubSegment(resp.bookingId, segment);
      // }
    } catch (e) {
      const message = e.message ? e.message : customErrorMessage;
      // toast.error(
      //   typeof message === "string" || message instanceof String
      //     ? message
      //     : customErrorMessage
      // );
      console.error(e, message);
    } finally {
      reload();
      onClose();
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  const loggedUserEmail = localStorage.getItem("email");
  const handleCashPayment = (bookingIds) => {
    djangoHandleDataMutationRequest("POST", "invoice/recordCashPayment/", {
      hotelId: hotelId,
      bookingID: bookingIds,
      amount: parseFloat(paymentAmount),
      offline_card_date: moment().format("YYYY-MM-DD HH:mm:ss"),
      service_amount_desc:
        remark !== "" ? remark : "CASH PAYMENT FROM QUICK BOOKING MODAL",
      logged_user_email: loggedUserEmail,
    })
      .then((res) => console.log())
      .catch((error) => toast.error(error));
  };

  const handleOfflineCardPayment = (bookingIds) => {
    djangoHandleDataMutationRequest(
      "POST",
      "invoice/recordOfflineCardPayment/",
      {
        hotelId: hotelId,
        bookingID: bookingIds,
        amount: parseFloat(paymentAmount),
        offline_card_date: moment().format("YYYY-MM-DD HH:mm:ss"),
        service_amount_desc:
          remark !== ""
            ? remark
            : "OFFLINE CARD PAYMENT FROM QUICK BOOKING MODAL",
        logged_user_email: loggedUserEmail,
      }
    )
      .then((res) => console.log())
      .catch((error) => toast.error(error));
  };

  const handleUpiPayment = (bookingIds) => {
    djangoHandleDataMutationRequest("POST", "invoice/recordUPIPayment/", {
      hotelId: hotelId,
      bookingID: bookingIds,
      amount: parseFloat(paymentAmount),
      offline_upi_date: moment().format("YYYY-MM-DD HH:mm:ss"),
      service_amount_desc:
        remark !== "" ? remark : "UPI PAYMENT FROM QUICK BOOKING MODAL",
      logged_user_email: loggedUserEmail,
    })
      .then((res) => console.log())
      .catch((error) => toast.error(error));
  };

  const handleOtherModePayment = (bookingIds) => {
    djangoHandleDataMutationRequest("POST", "invoice/recordOtherPayment/", {
      hotelId: hotelId,
      bookingID: bookingIds,
      amount: parseFloat(paymentAmount),
      other_payment_date: moment().format("YYYY-MM-DD HH:mm:ss"),
      service_amount_desc:
        remark !== "" ? remark : "OTHER PAYMENT FROM QUICK BOOKING MODAL",
      logged_user_email: loggedUserEmail,
    })
      .then((res) => console.log())
      .catch((error) => toast.error(error));
  };

  const handleBankTransferPayment = (bookingIds) => {
    djangoHandleDataMutationRequest(
      "POST",
      "invoice/recordBankTransferPayment/",
      {
        hotelId: hotelId,
        bookingID: bookingIds,
        reservation_id: "",
        amount: parseFloat(paymentAmount),
        bank_transfer_date: moment().format("YYYY-MM-DD HH:mm:ss"),
        service_amount_desc:
          remark !== ""
            ? remark
            : "BANK TRANSFER PAYMENT FROM GROUP BOOKING MODAL",
        logged_user_email: loggedUserEmail,
      }
    )
      .then((res) => console.log())
      .catch((err) => toast.error(err));
  };

  useEffect(() => {
    getBookingResources();
  }, []);
  const getCustomDates = (checkinSlots, checkoutSlots) => {
    const start = new Date(checkinDate);
    const end = new Date(checkoutDate);
    if (checkinSlots.length === 1) {
      start.setHours(checkinSlots[0]);
      end.setHours(checkoutSlots[0]);
    } else if (checkinSlots.length === 3) {
      start.setHours(checkinSlots[1]);
      end.setHours(checkoutSlots[1]);
    } else {
      start.setHours(checkinSlots[parseInt((checkinSlots.length - 1) / 2)]);
      end.setHours(checkoutSlots[parseInt((checkinSlots.length + 2) / 2)]);
    }
    setSelectedSlots({ checkin: start.getHours(), checkout: end.getHours() });
    return { checkin: start, checkout: end };
  };

  const getSlots = async () => {
    const slotsData = await getCheckinCheckoutSlots({
      checkin: moment(checkinDate).format(dateFormat),
      checkout: moment(checkoutDate).format(dateFormat),
    });
    setSlots({
      checkin: slotsData.checkinSlots.sort((a, b) => a - b),
      checkout: slotsData.checkoutSlots.sort((a, b) => a - b),
    });

    return getCustomDates(
      slotsData.checkinSlots.sort((a, b) => a - b),
      slotsData.checkoutSlots.sort((a, b) => a - b)
    );
  };

  const getDiscount = (original, editedPrice) => {
    var discount = 0;
    if (editedPrice > 0 && original > 0)
      discount = ((original - editedPrice) / original) * 100.0;
    else if (editedPrice === 0 && original > 0) discount = 100;
    else if (editedPrice > 0 && original === 0) discount = 0;
    else discount = 0;

    return discount;
  };
  const getCompletePriceForRoom = async (
    checkin,
    checkout,
    roomTypeId,
    ratePlanId,
    originalPrice,
    editedPrice,
    roomSelectionMap,
    adults
  ) => {
    try {
      const arr = [];
      const d = Object.entries(roomSelectionMap);
      d.forEach((entry) => {
        let obj_temp = {
          checkin: moment(checkinDate).format(dateFormat),
          checkout: moment(checkoutDate).format(dateFormat),
          roomTypeId: roomTypeId,
          ratePlanId: ratePlanId,
          numAdults: adults === "adults" ? 1 : entry[1].A,
          numChildren: entry[1].C,
          discount:
            adults === "discount"
              ? 0
              : getDiscount(parseFloat(originalPrice), parseFloat(editedPrice)),
        };
        arr.push(obj_temp);
      });

      const resp = await getCombinedPrice(arr);
      priceWithTax[roomTypeId].total = resp.total;
      priceWithTax[roomTypeId].taxes = resp.taxes;
      priceWithTax[roomTypeId].subtotal = resp.subtotal;
      perDayList[roomTypeId].allPerDayPrices = resp.perDayPriceList;
      setPriceWithTax({ ...priceWithTax });
      setPerDayList({ ...perDayList });
    } catch (e) {
      priceWithTax[roomTypeId].total = 0;
      priceWithTax[roomTypeId].taxes = 0;
      priceWithTax[roomTypeId].subtotal = 0;
      perDayList[roomTypeId].allPerDayPrices = [];
      setPriceWithTax({ ...priceWithTax });
      setPerDayList({ ...perDayList });
    }
  };

  const refetchBookingResources = async (checkin, checkout) => {
    setDataLoading(true);
    reset();
    setCheckinDate(checkin);
    setCheckoutDate(checkout);
    const payload = {
      hotelId: hotelId,
      checkin: moment(checkin).format(dateFormat),
      checkout: moment(checkout).format(dateFormat),
      isAdmin: true,
      ctaId:
        selectedCta.cta_email === "STAYFLEXI_HD" ? null : selectedCta.cta_id,
    };
    try {
      const data =
        hotel.slotType === "PURE_HOURLY"
          ? await getRoomBookingsNoon(payload)
          : await getRoomBookings(payload);
      let temp = data.roomTypeMap;
      let obj = {};
      let physicalRooms = {};
      let rates = {};
      let selectedRate = {};
      let taxMap = {};
      Object.keys(temp).forEach((roomId) => {
        temp[roomId].checkInPretty = data.checkInPretty;
        temp[roomId].checkOutPretty = data.checkOutPretty;
        obj[roomId] = temp[roomId].combos[0];
        physicalRooms[roomId] = obj[roomId].onlineRoomIds;
        rates[roomId] = obj[roomId].rates;
        selectedRate[roomId] = rates[roomId].sort(
          (a, b) => parseInt(a.order) - parseInt(b.order)
        )[0];
        temp[roomId].leastValue = rates[roomId][0].price;
        taxMap[roomId] = {
          total: Number(
            Number(rates[roomId][0].price) +
              Number(rates[roomId][0].taxes[temp[roomId].defaultOccupancy])
          ).toFixed(2),
          subtotal: rates[roomId][0].price,
          taxes: rates[roomId][0].taxes[temp[roomId].defaultOccupancy],
          changedAmount: rates[roomId][0].price.toFixed(2),
        };
        getCombinedPrice([
          {
            checkin: moment(checkin).format(dateFormat),
            checkout: moment(checkout).format(dateFormat),
            roomTypeId: roomId,
            ratePlanId: rates[roomId][0].ratePlanId,
            numAdults: temp[roomId].defaultOccupancy,
            numChildren: 0,
            discount: 0,
          },
        ]).then((resp) => {
          perDayList[roomId] = {};
          perDayList[roomId].allPerDayPrices = resp.perDayPriceList;
          setPerDayList({ ...perDayList });
        });
        roomPrice[roomId] = rates[roomId][0].price;
        numRooms[roomId] = 1;
        roomSelectionCount[roomId] = {
          1: { A: temp[roomId].defaultOccupancy, C: 0 },
        };
        roomIds[roomId] = [];
      });
      setBookingData(temp);
      setPriceWithTax(taxMap);
      setCombos(obj);
      setRatePlans(rates);
      setRoomIds(physicalRooms);
      setNumRooms(numRooms);
      setRoomPrice(roomPrice);
      setRoomIds({ ...roomIds });
      setRoomSelectionCount(roomSelectionCount);
      setSelectedRatePlan(selectedRate);
      setDataLoading(false);
      getTaxNames();
    } catch (e) {
      console.log(e);
      setLoading(false);
      setShowError(true);
    }
  };

  const getBookingResources = async (val) => {
    setDataLoading(true);
    reset();
    var dates = {};
    if (!val) {
      dates = await getSlots();
      setCheckinDate(dates.checkin);
      setCheckoutDate(dates.checkout);
    } else dates = { checkin: checkinDate, checkout: checkoutDate };
    const payload = {
      hotelId: hotelId,
      checkin: moment(dates.checkin).format(dateFormat),
      checkout: moment(dates.checkout).format(dateFormat),
      isAdmin: true,
      ctaId:
        selectedCta.cta_email === "STAYFLEXI_HD" ? null : selectedCta.cta_id,
    };
    try {
      const data =
        hotel.slotType === "PURE_HOURLY"
          ? await getRoomBookingsNoon(payload)
          : await getRoomBookings(payload);
      let temp = data.roomTypeMap;
      let obj = {};
      let physicalRooms = {};
      let rates = {};
      let selectedRate = {};
      let taxMap = {};
      Object.keys(temp).forEach((roomId) => {
        temp[roomId].checkInPretty = data.checkInPretty;
        temp[roomId].checkOutPretty = data.checkOutPretty;
        obj[roomId] = temp[roomId].combos[0];
        physicalRooms[roomId] = obj[roomId].onlineRoomIds;
        rates[roomId] = obj[roomId].rates;

        selectedRate[roomId] = rates[roomId].sort(
          (a, b) => parseInt(a.order) - parseInt(b.order)
        )[0];
        temp[roomId].leastValue = rates[roomId][0].price;
        taxMap[roomId] = {
          total: Number(
            Number(rates[roomId][0].price) +
              Number(rates[roomId][0].taxes[temp[roomId].defaultOccupancy])
          ).toFixed(2),
          subtotal: rates[roomId][0].price,
          taxes: rates[roomId][0].taxes[temp[roomId].defaultOccupancy],
          changedAmount: rates[roomId][0].price.toFixed(2),
        };
        getCombinedPrice([
          {
            checkin: moment(dates.checkin).format(dateFormat),
            checkout: moment(dates.checkout).format(dateFormat),
            roomTypeId: roomId,
            ratePlanId: rates[roomId][0].ratePlanId,
            numAdults: temp[roomId].defaultOccupancy,
            numChildren: 0,
            discount: 0,
          },
        ]).then((resp) => {
          perDayList[roomId] = {};
          perDayList[roomId].allPerDayPrices = resp.perDayPriceList;
          setPerDayList({ ...perDayList });
        });
        roomPrice[roomId] = rates[roomId][0].price;
        numRooms[roomId] = 1;
        roomSelectionCount[roomId] = {
          1: { A: temp[roomId].defaultOccupancy, C: 0 },
        };
        roomIds[roomId] = [];
      });
      if (!val) getCtaData();
      setBookingData(temp);
      setPriceWithTax(taxMap);
      setCombos(obj);
      setRatePlans(rates);
      setRoomIds(physicalRooms);
      setNumRooms(numRooms);
      setRoomPrice(roomPrice);
      setRoomIds({ ...roomIds });
      setRoomSelectionCount(roomSelectionCount);
      setSelectedRatePlan(selectedRate);
      setDataLoading(false);
      getTaxNames();
    } catch (e) {
      console.log(e);
      setLoading(false);
      setShowError(true);
    }
  };

  const getCtaData = () => {
    getCtaNamesNew().then((data) => {
      const newData = isArray(data) ? data : [];
      setCtas(ctas.concat(newData));
    });
  };

  const reset = () => {
    setTab(1);
    setShowRooms(false);
    setCurrentSelection(new Set());
    setShowError(false);
    formik.resetForm(formik.initialValues);
  };

  const formDisplayData = () => {
    let data = [];
    currentSelection.forEach((roomId) => {
      let obj = {};
      obj["roomTypeId"] = roomId;
      obj["selectionData"] = roomSelectionCount[roomId];
      obj["original"] = bookingData[roomId];
      obj["combos"] = combos[roomId];
      obj["ratePlan"] = selectedRatePlan[roomId];
      obj["roomCount"] = numRooms[roomId];
      obj["selectedRooms"] = roomIds[roomId];
      data.push(obj);
    });
    return data;
  };

  const getAllSelectedCount = (roomId) => {
    // console.log("roomId", roomIds);
    const tem = roomIds[roomId].filter((data) => {
      if (data) return data;
    });
    return tem;
  };

  const checkForRoomSelection = () => {
    let ret = false;
    Object.keys(roomIds).forEach((id) => {
      let d = getAllSelectedCount(id);
      if (d.length < numRooms[id] && currentSelection.has(id)) ret = true;
    });
    return ret;
  };

  const modifyCheckinSlots = async (checkin) => {
    let date2 = new Date(checkoutDate);

    if (new Date(date2).getTime() < new Date(checkin).getTime()) {
      let new_end_date = new Date(
        new Date(new Date(checkin).getTime() + 86400000).setHours(
          selectedSlots.checkout
        )
      );
      // date2.setDate(checkin.getDate() + 1);

      setCheckoutDate(new_end_date);
      date2 = new_end_date;
    }

    try {
      const slotsData = await getCheckinCheckoutSlots({
        checkin: moment(checkin).format(dateFormat),
        checkout: moment(date2).format(dateFormat),
      });
      refetchBookingResources(checkin, date2);
      const sortedArr = slotsData.checkinSlots.sort((a, b) => a - b);
      const sortedArrCheckout = slotsData.checkoutSlots.sort((a, b) => a - b);
      const selectedIndx = slots.checkin.indexOf(selectedSlots.checkin);
      const selectedIndxCheckout = slots.checkout.indexOf(
        selectedSlots.checkout
      );
      setSlots({
        checkin: sortedArr,
        checkout: sortedArrCheckout,
      });
      setSelectedSlots({
        checkin: sortedArr[selectedIndx],
        checkout: sortedArrCheckout[selectedIndxCheckout],
      });
    } catch (e) {
      console.log(e);
    }
  };

  const modifyCheckoutSlots = async (checkout) => {
    try {
      const slotsData = await getCheckinCheckoutSlots({
        checkin: moment(checkinDate).format(dateFormat),
        checkout: moment(checkout).format(dateFormat),
      });
      refetchBookingResources(checkinDate, checkout);
      const sortedArr = slotsData.checkoutSlots.sort((a, b) => a - b);
      const selectedIndx = slots.checkout.indexOf(selectedSlots.checkout);
      setSlots({
        checkin: slots.checkin,
        checkout: sortedArr,
      });
      setSelectedSlots({
        checkin: selectedSlots.checkin,
        checkout: sortedArr[selectedIndx],
      });
    } catch (e) {
      console.log(e);
    }
  };

  const [userDefinedSources, setUserDefinedSources] = useState(null);

  const getUserDefinedSources = () => {
    handleSpringDataRequest(`core/api/v1/user-defined-source/get-all-sources`)
      .then((res) => {
        setUserDefinedSources(res);
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  useEffect(() => {
    getUserDefinedSources();
  }, []);

  const [mapsss, setMapsss] = useState(null);

  useEffect(() => {
    const mp = {
      DIRECT: ["WALK-IN", "PHONE CALL", "EMAIL", "WHATSAPP", "SOCIAL MEDIA"],
      METASEARCH: ["GOOGLE", "TRIPADVISOR", "TRIVAGO", "KAYAK"],
      DIRECT_BILLING: ctas,
    };
    setMapsss(mp);
  }, [ctas]);

  useEffect(() => {
    if (mapsss && majorSources && userDefinedSources) {
      let tempser = majorSources;
      let temparr = [];
      const data = Object.keys(mapsss).map((res) => {
        temparr.push(res);
      });

      const datacs = tempser.map((res) => {
        temparr.push(res);
      });
      setMostMajorSource(temparr);
      setSegment(temparr[0]);
      let tempusersrs = userDefinedSources;
      let newarr = [];
      const dattsa = Object.entries(mapsss).map(([key, val]) => {
        newarr[key] = val;
      });
      const dattsass = Object.entries(tempusersrs).map(([key, val]) => {
        newarr[key] = val;
      });
      setMostSecondarySource(newarr);
    }
  }, [mapsss]);

  const [mostMajorSource, setMostMajorSource] = useState(null);
  const [mostSecondarySource, setMostSecondarySource] = useState(null);

  const [majorSources, setMajorSources] = useState(null);
  const [segment, setSegment] = useState("");
  useEffect(() => {
    if (userDefinedSources !== null) {
      let srs = Object.keys(userDefinedSources);
      setMajorSources(srs);
      setSegment(srs[0]);
    }
  }, [userDefinedSources]);

  const handleSegmentChange = (e) => {
    setSegment(e.target.value);
  };

  const [subSegment, setSubSegment] = useState("");
  const [subSegmentArr, setSubSegmentArr] = useState(null);
  useEffect(() => {
    if (segment !== null && mostSecondarySource !== null) {
      const arr = mostSecondarySource[segment];
      setSubSegmentArr(arr);
      if (typeof arr[0] === "object") setSubSegment(arr[0].cta_name);
      else setSubSegment(arr[0]);
    }
  }, [segment, mostSecondarySource]);

  const handleSubSegmentChange = (e) => {
    setSubSegment(e.target.value);
  };

  useEffect(() => {
    if (ctas !== null) {
      let val = null;
      const data = ctas.map((res) => {
        if (subSegment === res.cta_name) val = res;
      });
      if (val !== null) {
        setSelectedCta(val);
        // pricingRequestModel.ctaId = val.cta_id;
        // pricingRequestModel.resetParams();
        // fetchUpdatedBookingPricing();
      }
    }
  }, [subSegment]);
  const [disablePrices, setdisablePrices] = useState(true);
  const toggleShowPrices = (e) => {
    if (e.target.checked === true) {
      setdisablePrices(false);
    } else {
      setdisablePrices(true);
    }
  };
  useEffect(() => {
    setdisablePrices(hotelCountry === "United States" ? false : true);
  }, [hotelCountry]);

  //Document state upadtion
  const [documentPayload, setDocumentPayload] = useState(null);
  const setIshaDocumentPayload = (docPayload) => {
    setDocumentPayload(docPayload);
  };

  //room data retrieval
  const [roomData, setRoomData] = useState([]);
  const [primaryGuestIdProofs, setPrimaryGuestIdProofs] = useState([]);
  const [otherGuestIdProofs, setOtherGuestIdProofs] = useState([]);
  const [primaryGuestDocuments, setPrimaryGuestDocuments] = useState([]);
  const [otherGuestDocuments, setOtherGuestDocuments] = useState([]);

  // useEffect(() => {
  //   console.log("updated roomdata: ", roomData);
  //   console.log("updated primary guest id proofs: ", primaryGuestIdProofs);
  //   console.log("updated other guest id proofs: ", otherGuestIdProofs);
  //   console.log("updated primary guest documents: ", primaryGuestDocuments);
  //   console.log("updated other guest documents: ", otherGuestDocuments);
  // }, [
  //   roomData,
  //   primaryGuestIdProofs,
  //   primaryGuestDocuments,
  //   otherGuestDocuments,
  // ]);

  const [refUpdatedGuestData, setRefUpdatedGuestData] = useState(null);
  // useEffect(() => {
  //   console.log("ref updated guest data: ", refUpdatedGuestData);
  //   if (refUpdatedGuestData?.length > 0) {
  //     console.log("ref updated guest data: ", refUpdatedGuestData[0]);
  //   }
  // }, [refUpdatedGuestData]);

  function printRefData() {}

  const [showCreateBooking, setshowCreateBooking] = useState(false);
  useEffect(() => {
    handleSpringDataRequest(
      `core/api/v1/nightAudit/get-night-audit-flag?currentDate=${moment(
        checkinDate
      )
        .subtract(1, "days")
        .format("DD-MM-YYYY")}&opType=WALK_IN`
    )
      .then((res) => {
        if (
          props?.data?.accessControl?.ADMINRIGHTS === "ADMIN" ||
          props?.data?.accessControl?.ADMINRIGHTS === "SUBADMIN"
        ) {
          setshowCreateBooking(true);
        } else {
          setshowCreateBooking(res);
        }
      })
      .catch((err) => console.log(err));
  }, [checkinDate]);

  // useEffect(() => {
  //   console.log("combos", combos, "roomIds", roomIds);
  // }, [combos, roomIds]);

  // useEffect(() => {
  //   console.log("numRoomsnumRooms", numRooms);
  // }, [numRooms]);

  const throttledClick = throttle((data) => createBooking(data), 2000, {
    leading: true,
    trailing: false,
  });

  const [blockRoomCodeData, setblockRoomCodeData] = useState([]);
  useEffect(() => {
    if (checkIshaUSAccess(props?.data?.emailId)) {
      getAllBlockData();
    }
  }, []);

  const getAllBlockData = () => {
    handleSpringDataRequest(`core/api/v1/block-room-reason/get`)
      .then((res) => setblockRoomCodeData(res.map((item) => item.block_reason)))
      .catch((err) => console.error("Error in fetching the data"));
  };

  const [isBlackListedModal, setisBlackListedModal] = useState(false);
  const [blackListLoader, setblackListLoader] = useState(false);

  const OpenBlackListedModal = () => {
    setisBlackListedModal(true);
  };

  const closeBlacklistedModal = () => {
    setisBlackListedModal(false);
  };

  const checkBlackListed = () => {
    setblackListLoader(true);
    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/ota/search-for-blacklisted-user-us`,
      [
        {
          contact: {
            FirstName: primaryName.split(" ")[0],
            LastName: primaryName.split(" ")[1],
            Email: primaryEmail,
            Phone: primaryPhone,
          },
        },
      ]
    )
      .then((res) => {
        if (res.message === true || res.message === "true") {
          OpenBlackListedModal();
        } else {
          formik.handleSubmit();
        }
      })
      .catch((err) => {
        console.error(err);
        setblackListLoader(false);
      });
  };
  return (
    <Grid container>
      <form
        style={{ width: "inherit" }}
        noValidate
        onSubmit={formik.handleSubmit}
      >
        <Grid
          sx={{
            paddingTop: 2,
            paddingBottom: 2,
            paddingRight: 2,
            paddingLeft: 2,
          }}
          item
          spacing={2}
          container
          xs={12}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid item xs={1.5}>
              <DesktopDatePicker
                id="booking_start"
                name="booking_start"
                placeholder="Booking Start Date"
                // inputFormat="MMM dd, yyyy"
                inputFormat="dd MMM yyyy"
                sx={{
                  fontWeight: "bold !important",
                  padding: "0px",
                  margin: "0px",
                }}
                label="Checkin"
                value={checkinDate}
                onChange={(eve) => {
                  setCheckinDate(eve);
                  modifyCheckinSlots(eve);
                }}
                className="customizeDatePicker"
                renderInput={(params) => (
                  <TextField
                    // sx={{
                    //   fontWeight: "bold !important",
                    //   padding: "0px",
                    //   margin: "0px",
                    // }}
                    fullWidth
                    className="customizeDatePicker"
                    variant="outlined"
                    {...params}
                  />
                )}
              />

              {/* <DatePicker
                id="booking_start"
                name="booking_start"
                placeholder="Booking Start Date"
                value={checkinDate}
                inputFormat="DD-MM-YYYY"
                renderInput={(params) => (
                  <TextField
                    disabled
                    InputProps={{ readOnly: true }}
                    fullWidth
                    {...params}
                  />
                )}
                label="Checkin"
                onChange={(eve) => {
                  setCheckinDate(eve);
                  modifyCheckinSlots(eve);
                }}
              /> */}
            </Grid>
            <Grid item xs={1}>
              <TextField
                onChange={(e) => {
                  setSelectedSlots({
                    checkin: e.target.value,
                    checkout: selectedSlots.checkout,
                  });
                  checkinDate.setHours(e.target.value);
                  setCheckinDate(checkinDate);
                  refetchBookingResources(checkinDate, checkoutDate);
                }}
                label="Time"
                select
                value={selectedSlots.checkin}
              >
                {slots.checkin.map((slot) => (
                  <MenuItem key={slot} value={slot} disabled={slot === 24}>
                    {`${slot > 12 ? slot - 12 : slot} ${
                      (slot - 12) / 12 >= 0 && (slot - 12) / 12 !== 1
                        ? "PM"
                        : "AM"
                    }`}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={1.5}>
              <DesktopDatePicker
                id="booking_end"
                name="booking_end"
                placeholder="Booking End Date"
                // inputFormat="MMM dd, yyyy"
                inputFormat="dd MMM yyyy"
                sx={{
                  fontWeight: "bold !important",
                  padding: "0px",
                  margin: "0px",
                }}
                label="Checkout"
                value={checkoutDate}
                minDate={checkinDate}
                onChange={(eve) => {
                  setCheckoutDate(eve);
                  modifyCheckoutSlots(eve);
                }}
                className="customizeDatePicker"
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    sx={{
                      fontWeight: "bold !important",
                      padding: "0px",
                      margin: "0px",
                    }}
                    className="customizeDatePicker"
                    variant="outlined"
                    {...params}
                  />
                )}
              />
              {/* <DatePicker
                id="booking_end"
                name="booking_end"
                placeholder="Booking End Date"
                value={checkoutDate}
                minDate={checkinDate}
                inputFormat="DD-MM-YYYY"
                renderInput={(params) => (
                  <TextField
                    disabled
                    InputProps={{ readOnly: true }}
                    fullWidth
                    {...params}
                  />
                )}
                label="Checkout"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(eve) => {
                  setCheckoutDate(eve);
                  modifyCheckoutSlots(eve);
                }}
              /> */}
            </Grid>
            <Grid item xs={1}>
              <TextField
                label="Time"
                select
                onChange={(e) => {
                  setSelectedSlots({
                    checkin: selectedSlots.checkin,
                    checkout: e.target.value,
                  });
                  checkoutDate.setHours(e.target.value);
                  setCheckoutDate(checkoutDate);
                  refetchBookingResources(checkinDate, checkoutDate);
                }}
                value={selectedSlots.checkout}
              >
                {slots.checkout.map((slot) => (
                  <MenuItem key={slot} value={slot} disabled={slot === 24}>
                    {`${slot > 12 ? slot - 12 : slot} ${
                      (slot - 12) / 12 >= 0 && (slot - 12) / 12 !== 1
                        ? "PM"
                        : "AM"
                    }`}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </LocalizationProvider>

          <Grid item xs={2}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-helper-label">
                Segment
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={segment}
                label="Segment"
                onChange={handleSegmentChange}
              >
                {mostMajorSource &&
                  mostMajorSource.map((res) => {
                    if (mostSecondarySource[res]?.length > 0) {
                      return <MenuItem value={res}>{res}</MenuItem>;
                    }
                  })}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={2}>
            {/* <Autocomplete
              fullWidth
              options={ctas}
              getOptionLabel={(option) => {
                return option.cta_name;
              }}
              disableClearable
              isOptionEqualToValue={(opt, val) =>
                opt.cta_email === val.cta_email
              }
              onChange={(e, val) => {
                setSelectedCta(val);
              }}
              renderInput={(params) => <TextField {...params} label="Source" />}
              value={selectedCta}
            ></Autocomplete> */}
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-helper-label">
                Sub-segment
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={subSegment}
                label="Sub-segment"
                onChange={handleSubSegmentChange}
              >
                {subSegmentArr &&
                  subSegmentArr.map((res) => {
                    if (typeof res === "object") {
                      return (
                        <MenuItem value={res.cta_name}>{res.cta_name}</MenuItem>
                      );
                    }
                    return <MenuItem value={res}>{res}</MenuItem>;
                  })}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={2} alignSelf="center">
            <Button
              onClick={() => {
                getBookingResources(true);
              }}
              variant="custom-button"
            >
              Get Rooms
            </Button>
          </Grid>
          <Grid
            item
            xs={1}
            container
            justifyContent="flex-end"
            alignSelf="center"
          >
            <IconButton onClick={onClose}>
              <Close fontSize="large" />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            {" "}
            <Divider />{" "}
          </Grid>
          <Grid item xs={3}>
            <div className="d-flex align-items-center">
              <Button
                onClick={() => {
                  setTab(tab - 1);
                }}
                disabled={tab === 1}
                variant="custom-button"
              >
                Previous
              </Button>
              {JSON.parse(
                props?.data?.accessControl?.user_feature_map?.EditBookingPrice
              ).read === "YES" &&
                hotelCountry !== "United States" && (
                  <div className="d-flex align-items-center">
                    <Checkbox
                      {...label}
                      onChange={(e) => toggleShowPrices(e)}
                    />
                    <h4 className="mb-0">Edit price</h4>
                  </div>
                )}
            </div>
          </Grid>
          <Grid item container justifyContent="center" xs={7}>
            <Stepper activeStep={tab - 1} nonLinear alternativeLabel>
              <Step>
                <StepButton
                  color="primary"
                  disableRipple
                  onClick={() => {
                    setTab(1);
                  }}
                >
                  Select Rooms
                </StepButton>
              </Step>
              <Step>
                <StepButton
                  color={currentSelection.size <= 0 ? "" : "primary"}
                  disabled={
                    currentSelection.size <= 0 ||
                    (showRooms && checkForRoomSelection())
                  }
                  disableRipple
                  onClick={() => {
                    setTab(2);
                  }}
                >
                  Make Booking
                </StepButton>
              </Step>
            </Stepper>
          </Grid>
          <Grid
            item
            xs={2}
            container
            alignSelf="baseline"
            justifyContent="flex-end"
          >
            {tab === 1 && (
              <Button
                disabled={
                  currentSelection.size <= 0 ||
                  (showRooms && checkForRoomSelection())
                }
                onClick={() => {
                  setTab(tab + 1);
                }}
                variant="custom-button"
              >
                Next
              </Button>
            )}
            {tab === 2 && (
              <div className="d-flex">
                {moment(checkinDate).isSame(moment(), "day") &&
                  (showexpires === "false" || showexpires === false) && (
                    <div className="d-flex align-items-center mb-0">
                      <Checkbox
                        {...label}
                        onChange={(e) => toggleSelfCheckin(e)}
                      />
                      <h4 className="mr-3 mb-0">Checkin</h4>
                    </div>
                  )}
                {!checkIshaUSAccess(hotel?.emailId) && showCreateBooking && (
                  <LoadingButton
                    disabled={loading}
                    loading={loading}
                    type={"submit"}
                    variant="custom-button"
                    style={{ width: "9rem" }}
                  >
                    Create Booking
                  </LoadingButton>
                )}

                {checkIshaUSAccess(hotel?.emailId) && showCreateBooking && (
                  <LoadingButton
                    disabled={blackListLoader || loading}
                    loading={blackListLoader || loading}
                    onClick={checkBlackListed}
                    variant="custom-button"
                    style={{ width: "9rem" }}
                  >
                    Create Booking
                  </LoadingButton>
                )}
              </div>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} container>
          {tab === 1 && dataLoading && !showError && (
            <Grid item container xs={12}>
              {[...Array(2).keys()].map((num, idx) => {
                return (
                  <Box key={num * idx + 1} width={"inherit"}>
                    <Skeleton
                      sx={{ width: "97%", margin: 2 }}
                      variant="rectangular"
                      height={60}
                      animation="pulse"
                    />
                    <Skeleton
                      sx={{ width: "97%", margin: 2 }}
                      variant="rectangular"
                      height={45}
                      animation="wave"
                    />
                    <Skeleton
                      sx={{ width: "97%", margin: 2 }}
                      variant="rectangular"
                      height={30}
                      animation="pulse"
                    />
                    <Skeleton
                      sx={{ width: "97%", margin: 2 }}
                      variant="rectangular"
                      height={15}
                      animation="wave"
                    />
                  </Box>
                );
              })}
            </Grid>
          )}
          {showError && (
            <Grid item xs={12} justifyContent="center" container>
              <Typography variant="h3">
                {" "}
                No Rooms available for the current selection
              </Typography>
            </Grid>
          )}
          {Object.entries(bookingData).length > 0 ? (
            tab === 1 &&
            !dataLoading &&
            !showError && (
              <Grow in timeout={1000}>
                <Grid item xs={12} container padding={2}>
                  {Object.keys(bookingData).map((roomTypeId, idx) => {
                    return (
                      bookingData[roomTypeId].hidden === "no" && (
                        <Grid item key={roomTypeId} xs={12}>
                          <StyledAccordion
                            sx={{
                              "& .MuiTypography-root": {
                                //use sx and select related class
                                fontWeight: "bold", // change its style
                              },
                            }}
                            defaultExpanded={
                              currentSelection.has(roomTypeId)
                                ? true
                                : currentSelection.size === 0 && idx === 0
                                ? true
                                : false
                            }
                          >
                            <StyledAccordionSummary>
                              <Grid container width="100%">
                                <Grid
                                  item
                                  xs={6}
                                  justifyContent="flex-start"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "13px",
                                  }}
                                >
                                  {" "}
                                  {bookingData[roomTypeId].roomTypeName}{" "}
                                </Grid>
                                <Grid
                                  item
                                  display="flex"
                                  xs={6}
                                  justifyContent="flex-end"
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "13px",
                                  }}
                                >
                                  Starting at{" "}
                                  {` ${hotelCurrency} ${bookingData[roomTypeId].leastValue}`}
                                </Grid>
                              </Grid>
                            </StyledAccordionSummary>
                            <StyledAccordionDetails>
                              <Grid
                                container
                                sx={{ width: "100%", padding: 2 }}
                                columnSpacing={3}
                              >
                                <Grid item xs={8} container spacing={2}>
                                  <Grid item xs={12}>
                                    <Typography variant="subtitle1">
                                      {bookingData[roomTypeId].checkInPretty}{" "}
                                      <SwapHoriz sx={{ mr: 1, ml: 1 }} />
                                      {bookingData[roomTypeId].checkOutPretty}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Divider />
                                  </Grid>
                                  <Grid item xs={12} container spacing={2}>
                                    <Grid item xs={6}>
                                      <TextField
                                        value={priceWithTax[roomTypeId].total}
                                        fullWidth
                                        disabled
                                        style={{ fontWeight: "bold" }}
                                        InputProps={{
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              {hotelCurrency}
                                            </InputAdornment>
                                          ),
                                        }}
                                        label="Total Price including taxes"
                                      ></TextField>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <TextField
                                        select
                                        onChange={(eve) => {
                                          let d = eve.target.value;
                                          selectedRatePlan[roomTypeId] = d;
                                          setSelectedRatePlan({
                                            ...selectedRatePlan,
                                          });
                                          getCompletePriceForRoom(
                                            combos[roomTypeId].checkIn,
                                            combos[roomTypeId].checkOut,
                                            roomTypeId,
                                            d.ratePlanId,
                                            0,
                                            0,
                                            roomSelectionCount[roomTypeId]
                                          );
                                        }}
                                        style={{ fontWeight: "bold" }}
                                        value={selectedRatePlan[roomTypeId]}
                                        fullWidth
                                        label="Rate Plan"
                                        disabled={addedEntryCheck[idx]}
                                      >
                                        {ratePlans[roomTypeId]
                                          .sort(
                                            (a, b) =>
                                              parseInt(a.order) -
                                              parseInt(b.order)
                                          )
                                          .map((eachRatePlan) => (
                                            <MenuItem
                                              key={eachRatePlan.ratePlanId}
                                              value={eachRatePlan}
                                            >
                                              {eachRatePlan.ratePlanName}
                                            </MenuItem>
                                          ))}
                                      </TextField>
                                    </Grid>
                                  </Grid>
                                  <Grid item xs={12} container spacing={2}>
                                    <Grid item xs={6}>
                                      <TextField
                                        fullWidth
                                        style={{ fontWeight: "bold" }}
                                        value={
                                          bookingData[roomTypeId].maxOccupancy
                                        }
                                        disabled
                                        label="Max Occupancy"
                                      ></TextField>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <TextField
                                        type="number"
                                        InputProps={{
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              {hotelCurrency}
                                            </InputAdornment>
                                          ),
                                        }}
                                        disabled={disablePrices}
                                        value={
                                          priceWithTax[roomTypeId].subtotal
                                        }
                                        fullWidth
                                        onChange={(e) => {
                                          priceWithTax[roomTypeId].subtotal =
                                            e.target.value;
                                          setPriceWithTax({ ...priceWithTax });
                                        }}
                                        onBlur={(e) => {
                                          getCompletePriceForRoom(
                                            combos[roomTypeId].checkIn,
                                            combos[roomTypeId].checkOut,
                                            roomTypeId,
                                            selectedRatePlan[roomTypeId]
                                              .ratePlanId,
                                            selectedRatePlan[roomTypeId].price,
                                            e.target.value,
                                            roomSelectionCount[roomTypeId],
                                            "adults"
                                          );
                                        }}
                                        // helperText="*Enter your custom final price here. Discount will be calculated accordingly."
                                        label="Price per room excluding taxes"
                                      ></TextField>
                                    </Grid>
                                  </Grid>
                                  <Grid item xs={12} container spacing={2}>
                                    <Grid item container xs={6}>
                                      <Grid item xs={6}>
                                        <ListItemText
                                          primary="Number of Rooms"
                                          secondary={`Available : ${combos[roomTypeId].availableRooms}`}
                                        ></ListItemText>
                                      </Grid>
                                      <Grid item xs={6}>
                                        <ButtonGroup>
                                          <Button
                                            variant="text"
                                            disabled={
                                              showRooms ||
                                              numRooms[roomTypeId] <= 1 ||
                                              addedEntryCheck[idx]
                                            }
                                            onClick={() => {
                                              numRooms[roomTypeId] =
                                                numRooms[roomTypeId] - 1;
                                              setNumRooms({ ...numRooms });
                                              const new_data = Object.assign(
                                                {},
                                                roomSelectionCount
                                              );
                                              delete new_data[roomTypeId][
                                                numRooms[roomTypeId] + 1
                                              ];
                                              setRoomSelectionCount({
                                                ...new_data,
                                              });
                                              getCompletePriceForRoom(
                                                combos[roomTypeId].checkIn,
                                                combos[roomTypeId].checkOut,
                                                roomTypeId,
                                                selectedRatePlan[roomTypeId]
                                                  .ratePlanId,
                                                selectedRatePlan[roomTypeId]
                                                  .price,
                                                priceWithTax[roomTypeId]
                                                  .changedAmount,
                                                new_data[roomTypeId],
                                                "discount"
                                              );
                                            }}
                                          >
                                            <Remove />
                                          </Button>
                                          <Button variant={"text"} disabled>
                                            {numRooms[roomTypeId]}
                                          </Button>
                                          <Button
                                            disabled={
                                              showRooms ||
                                              numRooms[roomTypeId] >=
                                                combos[roomTypeId]
                                                  .availableRooms ||
                                              addedEntryCheck[idx]
                                            }
                                            onClick={() => {
                                              numRooms[roomTypeId] =
                                                numRooms[roomTypeId] + 1;
                                              roomSelectionCount[roomTypeId][
                                                numRooms[roomTypeId]
                                              ] = {
                                                A: bookingData[roomTypeId]
                                                  .defaultOccupancy,
                                                C: 0,
                                              };
                                              setNumRooms({ ...numRooms });
                                              setRoomSelectionCount({
                                                ...roomSelectionCount,
                                              });
                                              const new_data = Object.assign(
                                                {},
                                                roomSelectionCount
                                              );
                                              setRoomSelectionCount({
                                                ...new_data,
                                              });
                                              getCompletePriceForRoom(
                                                combos[roomTypeId].checkIn,
                                                combos[roomTypeId].checkOut,
                                                roomTypeId,
                                                selectedRatePlan[roomTypeId]
                                                  .ratePlanId,
                                                selectedRatePlan[roomTypeId]
                                                  .price,
                                                priceWithTax[roomTypeId]
                                                  .changedAmount,
                                                new_data[roomTypeId],
                                                "discount"
                                              );
                                            }}
                                            variant="text"
                                          >
                                            <Add />
                                          </Button>
                                        </ButtonGroup>
                                      </Grid>
                                    </Grid>
                                    <Grid item container xs={6} spacing={2}>
                                      <Grid item xs={6}>
                                        <ListItemText
                                          primary="Adults"
                                          secondary={`Default Occupancy : ${bookingData[roomTypeId].defaultOccupancy}`}
                                        ></ListItemText>
                                      </Grid>
                                      <Grid item xs={6}>
                                        <ListItemText
                                          primary="Children"
                                          secondary={`Max Children : ${bookingData[roomTypeId].maxChildren}`}
                                        ></ListItemText>
                                      </Grid>
                                      {[
                                        ...Array(numRooms[roomTypeId]).keys(),
                                      ].map((val, indxNum) => {
                                        let countIndividual =
                                          roomSelectionCount[roomTypeId];
                                        return (
                                          <Grid
                                            container
                                            key={roomTypeId + val + 1}
                                            item
                                            xs={12}
                                          >
                                            <Grid item xs={6}>
                                              <ButtonGroup>
                                                <Button
                                                  disabled={
                                                    countIndividual[val + 1][
                                                      "A"
                                                    ] < 2 ||
                                                    addedEntryCheck[idx]
                                                  }
                                                  onClick={() => {
                                                    countIndividual[val + 1][
                                                      "A"
                                                    ] =
                                                      countIndividual[val + 1][
                                                        "A"
                                                      ] - 1;
                                                    setRoomSelectionCount({
                                                      ...roomSelectionCount,
                                                    });
                                                    getCompletePriceForRoom(
                                                      combos[roomTypeId]
                                                        .checkIn,
                                                      combos[roomTypeId]
                                                        .checkOut,
                                                      roomTypeId,
                                                      selectedRatePlan[
                                                        roomTypeId
                                                      ].ratePlanId,
                                                      0,
                                                      0,
                                                      roomSelectionCount[
                                                        roomTypeId
                                                      ]
                                                    );
                                                  }}
                                                  variant="text"
                                                >
                                                  <Remove />
                                                </Button>
                                                <Button
                                                  variant={"text"}
                                                  disabled
                                                >
                                                  {countIndividual[val + 1]
                                                    ? countIndividual[val + 1][
                                                        "A"
                                                      ]
                                                    : bookingData[roomTypeId]
                                                        .defaultOccupancy}
                                                </Button>
                                                <Button
                                                  disabled={
                                                    countIndividual[val + 1][
                                                      "A"
                                                    ] +
                                                      countIndividual[val + 1][
                                                        "C"
                                                      ] >=
                                                      bookingData[roomTypeId]
                                                        .maxPossible ||
                                                    countIndividual[val + 1][
                                                      "A"
                                                    ] >=
                                                      bookingData[roomTypeId]
                                                        ?.maxOccupancy ||
                                                    addedEntryCheck[idx]
                                                  }
                                                  onClick={() => {
                                                    countIndividual[val + 1][
                                                      "A"
                                                    ] =
                                                      countIndividual[val + 1][
                                                        "A"
                                                      ] + 1;
                                                    setRoomSelectionCount({
                                                      ...roomSelectionCount,
                                                    });
                                                    getCompletePriceForRoom(
                                                      combos[roomTypeId]
                                                        .checkIn,
                                                      combos[roomTypeId]
                                                        .checkOut,
                                                      roomTypeId,
                                                      selectedRatePlan[
                                                        roomTypeId
                                                      ].ratePlanId,
                                                      0,
                                                      0,
                                                      roomSelectionCount[
                                                        roomTypeId
                                                      ]
                                                    );
                                                  }}
                                                  variant="text"
                                                >
                                                  <Add />
                                                </Button>
                                              </ButtonGroup>
                                            </Grid>
                                            <Grid
                                              container
                                              justifyContent="flex-start"
                                              item
                                              xs={6}
                                            >
                                              <ButtonGroup>
                                                <Button
                                                  disabled={
                                                    countIndividual[val + 1][
                                                      "C"
                                                    ] <= 0 ||
                                                    addedEntryCheck[idx]
                                                  }
                                                  onClick={() => {
                                                    countIndividual[val + 1][
                                                      "C"
                                                    ] =
                                                      countIndividual[val + 1][
                                                        "C"
                                                      ] - 1;
                                                    setRoomSelectionCount({
                                                      ...roomSelectionCount,
                                                    });
                                                    getCompletePriceForRoom(
                                                      combos[roomTypeId]
                                                        .checkIn,
                                                      combos[roomTypeId]
                                                        .checkOut,
                                                      roomTypeId,
                                                      selectedRatePlan[
                                                        roomTypeId
                                                      ].ratePlanId,
                                                      0,
                                                      0,
                                                      roomSelectionCount[
                                                        roomTypeId
                                                      ]
                                                    );
                                                  }}
                                                  variant="text"
                                                >
                                                  <Remove />
                                                </Button>
                                                <Button
                                                  variant={"text"}
                                                  disabled
                                                >
                                                  {countIndividual[val + 1]
                                                    ? countIndividual[val + 1][
                                                        "C"
                                                      ]
                                                    : 0}
                                                </Button>
                                                <Button
                                                  onClick={() => {
                                                    countIndividual[val + 1][
                                                      "C"
                                                    ] =
                                                      countIndividual[val + 1][
                                                        "C"
                                                      ] + 1;
                                                    setRoomSelectionCount({
                                                      ...roomSelectionCount,
                                                    });
                                                    getCompletePriceForRoom(
                                                      combos[roomTypeId]
                                                        .checkIn,
                                                      combos[roomTypeId]
                                                        .checkOut,
                                                      roomTypeId,
                                                      selectedRatePlan[
                                                        roomTypeId
                                                      ].ratePlanId,
                                                      0,
                                                      0,
                                                      roomSelectionCount[
                                                        roomTypeId
                                                      ]
                                                    );
                                                  }}
                                                  disabled={
                                                    countIndividual[val + 1][
                                                      "A"
                                                    ] +
                                                      countIndividual[val + 1][
                                                        "C"
                                                      ] >=
                                                      bookingData[roomTypeId]
                                                        .maxPossible ||
                                                    countIndividual[val + 1][
                                                      "C"
                                                    ] >=
                                                      bookingData[roomTypeId]
                                                        .maxChildren ||
                                                    addedEntryCheck[idx]
                                                  }
                                                  variant="text"
                                                >
                                                  <Add />
                                                </Button>
                                              </ButtonGroup>
                                            </Grid>
                                          </Grid>
                                        );
                                      })}
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item container xs={4}>
                                  <Grid item xs={12}>
                                    <FormControlLabel
                                      sx={{ fontWeight: "bold" }}
                                      control={
                                        <Checkbox
                                          onClick={(ele) => {
                                            if (showRooms) {
                                              roomIds[roomTypeId] = [];
                                              setRoomIds({ ...roomIds });
                                            }
                                            setShowRooms(!showRooms);
                                          }}
                                          checked={showRooms}
                                          disabled={addedEntryCheck[idx]}
                                        />
                                      }
                                      label="Enable Physical Room Allocation"
                                    ></FormControlLabel>
                                    <FormHelperText>
                                      {" "}
                                      Pro Tip: We strongly encourage to let our
                                      system choose room IDs automatically.
                                    </FormHelperText>
                                    <Grid item container xs={12}>
                                      {showRooms && (
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexWrap: "wrap",
                                            gap: 1,
                                          }}
                                        >
                                          {combos[roomTypeId][
                                            "onlineRoomIds"
                                          ].map((val, index) => {
                                            return (
                                              <Button
                                                key={val}
                                                onClick={() => {
                                                  if (
                                                    roomIds[roomTypeId][index]
                                                  ) {
                                                    roomIds[roomTypeId][index] =
                                                      !roomIds[roomTypeId][
                                                        index
                                                      ];
                                                    setRoomIds({ ...roomIds });
                                                  } else {
                                                    let ar =
                                                      getAllSelectedCount(
                                                        roomTypeId
                                                      );
                                                    if (
                                                      numRooms[roomTypeId] >
                                                      ar.length
                                                    ) {
                                                      roomIds[roomTypeId][
                                                        index
                                                      ] =
                                                        !roomIds[roomTypeId][
                                                          index
                                                        ];
                                                      setRoomIds({
                                                        ...roomIds,
                                                      });
                                                    }
                                                  }
                                                }}
                                                variant={
                                                  roomIds[roomTypeId][index]
                                                    ? "contained"
                                                    : "outlined"
                                                }
                                              >
                                                {" "}
                                                {val}
                                              </Button>
                                            );
                                          })}

                                          {combos[roomTypeId][
                                            "offlineRoomIds"
                                          ].map((val, index) => {
                                            return (
                                              <Button
                                                key={val}
                                                onClick={() => {
                                                  if (
                                                    roomIds[roomTypeId][
                                                      combos[roomTypeId][
                                                        "onlineRoomIds"
                                                      ]?.length + index
                                                    ]
                                                  ) {
                                                    roomIds[roomTypeId][
                                                      combos[roomTypeId][
                                                        "onlineRoomIds"
                                                      ]?.length + index
                                                    ] =
                                                      !roomIds[roomTypeId][
                                                        combos[roomTypeId][
                                                          "onlineRoomIds"
                                                        ]?.length + index
                                                      ];
                                                    setRoomIds({ ...roomIds });
                                                  } else {
                                                    let ar =
                                                      getAllSelectedCount(
                                                        roomTypeId
                                                      );
                                                    if (
                                                      numRooms[roomTypeId] >
                                                      ar.length
                                                    ) {
                                                      roomIds[roomTypeId][
                                                        combos[roomTypeId][
                                                          "onlineRoomIds"
                                                        ]?.length + index
                                                      ] =
                                                        !roomIds[roomTypeId][
                                                          combos[roomTypeId][
                                                            "onlineRoomIds"
                                                          ]?.length + index
                                                        ];
                                                      setRoomIds({
                                                        ...roomIds,
                                                      });
                                                    }
                                                  }
                                                }}
                                                variant={
                                                  roomIds[roomTypeId][
                                                    combos[roomTypeId][
                                                      "onlineRoomIds"
                                                    ]?.length + index
                                                  ]
                                                    ? "contained"
                                                    : "outlined"
                                                }
                                                color="secondary"
                                              >
                                                {" "}
                                                {val}
                                              </Button>
                                            );
                                          })}
                                        </Box>
                                      )}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </StyledAccordionDetails>
                            <AccordionActions>
                              <Button
                                disabled={
                                  showRooms &&
                                  getAllSelectedCount(roomTypeId).length !==
                                    numRooms[roomTypeId]
                                }
                                startIcon={
                                  currentSelection.has(roomTypeId) ? (
                                    <Delete />
                                  ) : (
                                    <Add />
                                  )
                                }
                                variant="custom-button"
                                color={
                                  currentSelection.has(roomTypeId)
                                    ? "error"
                                    : "primary"
                                }
                                onClick={() => {
                                  if (currentSelection.has(roomTypeId)) {
                                    currentSelection.delete(roomTypeId);
                                    updateAddedEntry(idx, false);
                                  } else {
                                    currentSelection.add(roomTypeId);
                                    updateAddedEntry(idx, true);
                                  }
                                  setCurrentSelection(
                                    new Set(currentSelection)
                                  );
                                }}
                              >
                                {currentSelection.has(roomTypeId)
                                  ? "Remove"
                                  : "Add"}
                              </Button>
                            </AccordionActions>
                          </StyledAccordion>
                        </Grid>
                      )
                    );
                  })}
                </Grid>
              </Grow>
            )
          ) : (
            <Grid item xs={12} justifyContent="center" container>
              <Typography variant="h3">
                {" "}
                {dataLoading === false &&
                  "No Rooms available for the current selection"}
              </Typography>
            </Grid>
          )}
          {/* {tab === 2 && (
            <DetailsReview
              formik={formik}
              currentSelection={currentSelection}
              priceWithTax={priceWithTax}
              perDayList={perDayList}
              checkin={checkinDate}
              checkout={checkoutDate}
              datas={formDisplayData()}
              showRooms={showRooms}
              taxNames={taxNames}
              hotelCurrency={hotelCurrency}
              setUpdatedOtherGuestDetails={(otherGuests) =>
                setOtherGuestDataView(otherGuests)
              }
              setUpdatedOtherGuestDocuments={(otherGuests) =>
                setOtherGuestDocumentsView(otherGuests)
              }
              setUpdatedOtherGuestIdProofs={(otherGuests) =>
                setOtherGuestIdProofsView(otherGuests)
              }
              setIshaDocuments={setIshaDocumentPayload}
              roomIds={roomIds}
            ></DetailsReview>
          )} */}
          {tab === 2 && (
            <GroupBookingDetails
              formik={formik}
              currentSelection={currentSelection}
              priceWithTax={priceWithTax}
              perDayList={perDayList}
              checkin={checkinDate}
              checkout={checkoutDate}
              datas={formDisplayData()}
              showRooms={showRooms}
              taxNames={taxNames}
              hotelCurrency={hotelCurrency}
              roomIds={roomIds}
              updateRoomData={setRoomData}
              updatePrimaryGuestIdProof={setPrimaryGuestIdProofs}
              updateOtherGuestIdProofs={setOtherGuestIdProofs}
              updatePrimaryGuestDocuments={setPrimaryGuestDocuments}
              updateOtherGuestDocumentsFunc={setOtherGuestDocuments}
              updateRefData={setRefUpdatedGuestData}
              printRefData={printRefData}
              selectedRatePlan={selectedRatePlan}
              roomSelectionCount={roomSelectionCount}
              hotelCountry={hotelCountry}
              addCardFirstName={addCardFirstName}
              setAddCardFirstName={setAddCardFirstName}
              addCardLastName={addCardLastName}
              setAddCardLastName={setAddCardLastName}
              addCardNumber={addCardNumber}
              setAddCardNumber={setAddCardNumber}
              addCardType={addCardType}
              setAddCardType={setAddCardType}
              addCardCVV={addCardCVV}
              setAddCardCVV={setAddCardCVV}
              addCardExpiry={addCardExpiry}
              setAddCardExpiry={setAddCardExpiry}
              error={error}
              setError={setError}
              paymentMode={paymentMode}
              setpaymentMode={setpaymentMode}
              paymentAmount={paymentAmount}
              setpaymentAmount={setpaymentAmount}
              remark={remark}
              setremark={setremark}
              getCardType={getCardType}
              checkLuhn={checkLuhn}
              cardNumberCVVFormat={cardNumberCVVFormat}
              checkExpiry={checkExpiry}
              showCollectpayment={showCollectpayment}
              setshowCollectpayment={setshowCollectpayment}
              toggleCollectpayment={toggleCollectpayment}
              blockRoomCodeData={blockRoomCodeData}
              blockReasonType={blockReasonType}
              setblockReasonType={setblockReasonType}
              setprimaryEmail={setprimaryEmail}
              setprimaryPhone={setprimaryPhone}
              setprimaryName={setprimaryName}
            />
          )}
        </Grid>
        <Modal
          show={isBlackListedModal}
          onHide={closeBlacklistedModal}
          style={{ zIndex: "3000" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>{"Blacklisted guest"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Grid container>
              <Grid item xs={12}>
                <h3>
                  This is a blacklisted guest, would you still want to proceed ?
                </h3>
              </Grid>
            </Grid>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="custom-button"
              onClick={() => formik.handleSubmit()}
            >
              YES
            </Button>
          </Modal.Footer>
        </Modal>
      </form>
    </Grid>
  );
};

function mapStateToProps(state) {
  return { data: state.auth.selectedHotel };
}

export default connect(mapStateToProps)(MultipleBookingView);
